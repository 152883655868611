.Notfound-wrapper{
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding:10px;
}
.Notfound-container{
  width: 100%;
  margin:0 auto;
  text-align: center;
}

.Notfound-wrapper h1{
  font-size: 80px;
  font-weight: 900;
  color: $PrimaryColor;
}
.Notfound-wrapper h2{
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 10px;
}
