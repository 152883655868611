
/*------------------------------------------------------------------------------
  REACT DATE PICKER
------------------------------------------------------------------------------*/
.react-datepicker-wrapper {
  display: block!important;
  padding: 0!important;
  border: 0!important;
  max-width: 100%!important;
}



/*------------------------------------------------------------------------------
  DROPZONE
------------------------------------------------------------------------------*/
.dropIcon [class*=" uil-"]:before,
.dropIcon [class^=uil-]:before{
  font-size: 25px!important;
}
