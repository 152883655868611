body.login{
  background: #f2f2f3;
}

.login-wrapper{
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding:10px;
}
.login-container{
  width: 95%;
}


.login-logo-wrapper{
  padding: 10px 0 5px 0;
  text-align: center;
  //border-bottom: 1px solid $BorderColorDark;
}
.login-logo-wrapper img{
  max-width: 270px;
  margin:0 auto;
}

.login-panel{
  box-shadow: 0 0 5px rgba(0,0,0,.5);
  background: #fff;
  border-radius: 5px;
}

.login-header{
  display: flex;
  align-items: center;
  padding:20px 20px 20px 20px;
  border-bottom: 1px solid $BorderColorDark;
}
.login-header h1{
  font-weight: 700;
}
.login-header p{
  opacity: 0.8;
}
.login-header img{
  max-width: 45px;
  border-radius: 50%;
  margin-right: 15px;
}

.login-body{
  padding:30px 20px;
}


.login-footer{
  border-top: 1px solid $BorderColorDark;
  padding:20px;
  text-align: center;
}
.login-footer p{
  margin-top: 10px;
}
.login-footer a{
  text-decoration: underline;
}

.login-footer>div{
  padding:5px 0;
}

.copyright-wrapper{
  text-align: center;
  padding-top: 10px;
}
.copyright-wrapper p{
  font-size: 12px;
  opacity: 0.7;
}


@media screen and (min-width: 768px) {
  .login-container{
    width: 50%;
  }
}

@media screen and (min-width: 1024px) {
  .login-container{
    width: 30%;
  }
}
@media screen and (min-width: 1366px) {
  .login-container{
    width: 25%;
  }
}
@media screen and (min-width: 1680px) {
  .login-container{
    width: 18%;
  }
}
