/*-----------------------------------------------------------
 PANEL
-----------------------------------------------------------*/
.panel{
  border-radius: 5px;
  background: #fff;
}
.panel.hasTop{
  margin-top: 10px;
}
.panel-header{
  padding:10px 15px;
  border-bottom: 1px solid $BorderColor;
}
.panel-header h2{
  font-weight: 700;
}
.panel-body{
  padding:20px;
}
.panel-footer{
  border-top: 1px solid $BorderColor;
  padding:10px 20px;
}


.buttonPageFooter{
  margin-top: 10px;
}

/*-----------------------------
 Inside Panel Body
 .Layout customer information
-------------------------------*/
.layoutBox-wrapper{
  display: flex;
}
.layoutBox-wrapper>div{
  flex-basis: 45%;
}
