@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kodchasan:wght@500;600;700&display=swap");
/* ---------------------------------
 Media Queries
 $FontFamily: 'Nunito Sans', sans-serif;
 @media screen and (min-width: 768px) {
 }
 768px
 1024px
 1366px
 1440px
 1680px
 1920px
---------------------------------- */
/* ---------------------------------
 COLOR
---------------------------------- */
/* ---------------------------------
 BACKGROUND COLOR
---------------------------------- */
/* ---------------------------------
 BORDER
---------------------------------- */
/* ---------------------------------
   SCROLLBAR
---------------------------------- */
/* ---------------------------------
   THEME COLOR AND BACKGROUND HEADER
---------------------------------- */
/* ---------------------------------
 Z-INDEX
---------------------------------- */
/* ---------------------------------
  CONFIG WITH AND MARGIN
---------------------------------- */
/* ---------------------------------
   SIDEBAR THEME
---------------------------------- */
/* ---------------------------------
   MENU THEME
---------------------------------- */
/* ---------------------------------
   FOOTER THEME
---------------------------------- */
/* ---------------------------------
   THEME SIDEBAR WIDTH
---------------------------------- */
/* ---------------------------------
   IMPORT STYLE
---------------------------------- */
/*---------------------------------
  RESET
----------------------------------*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, textarea {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 14px;
  vertical-align: baseline;
  color: #181a1f;
  font-family: "Nunito Sans", sans-serif; }

html, body {
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  color: #181a1f;
  font-weight: normal;
  scroll-behavior: smooth;
  background: #f2f2f3; }

body.modal {
  overflow: hidden; }

ul {
  list-style-type: none;
  margin: 0;
  padding: 0; }

img {
  display: block;
  margin: 0;
  padding: 0; }

a {
  color: inherit; }

h1, h2, h3, h4, h5 {
  font-weight: normal;
  margin: 0;
  padding: 0; }

h1 {
  font-size: 20px; }

h2 {
  font-size: 18px; }

.display_none {
  display: none; }

#display_none {
  display: none; }

.clearfix:after, .clearfix:before {
  content: " ";
  display: table; }

body.noscroll {
  margin: 0;
  height: 100%;
  overflow: hidden; }

/*----------------------------------------------------------
  BUTTON DEFAULT
-----------------------------------------------------------*/
.ui.button.tiny [class*=" uil-"]:before,
.ui.button.tiny [class^=uil-]:before {
  font-size: 17px; }

.ui.button {
  font-weight: normal;
  color: #181a1f; }

.ui.uppercase.button {
  text-transform: uppercase; }

.ui.strong.button {
  font-weight: 800; }

/*----------------------------------------------------------
  SPECIFIC BUTTON
-----------------------------------------------------------*/
.ui.PrimaryButton.button i {
  color: #fff; }

.ui.PrimaryButton.button, .ui.PrimaryButton.buttons .button {
  background: #e41e2f;
  color: #fff !important; }

.ui.PrimaryButton.button:hover, .ui.PrimaryButton.buttons:hover .button:hover,
.ui.PrimaryButton.button:focus, .ui.PrimaryButton.buttons:focus .button:focus {
  background: #e41e2f;
  color: #fff !important;
  opacity: 0.9; }

.ui.DarkButton.button i {
  color: #fff; }

.ui.DarkButton.button, .ui.DarkButton.buttons .button {
  background: #262626;
  color: #fff !important; }

.ui.DarkButton.button:hover, .ui.DarkButton.buttons:hover .button:hover,
.ui.DarkButton.button:focus, .ui.DarkButton.buttons:focus .button:focus {
  background: #262626;
  color: #fff !important;
  opacity: 0.9; }

.ui.BasicPrimary.button, .ui.BasicPrimary.buttons .button {
  background: #fff;
  border: 1px solid #d6d6d8; }

.ui.BasicPrimary.button:hover, .ui.BasicPrimary.buttons:hover .button:hover,
.ui.BasicPrimary.button:focus, .ui.BasicPrimary.buttons:focus .button:focus {
  background: #f2f2f2;
  border: 1px solid #d6d6d8; }

.ui.BasicButton.button, .ui.BasicButton.buttons .button {
  background: #ebedf1; }

.ui.BasicButton.button:hover, .ui.BasicButton.buttons:hover .button:hover,
.ui.BasicButton.button:focus, .ui.BasicButton.buttons:focus .button:focus {
  background: #dfe1e9; }

.ui.RedButton.button i {
  color: #a94658 !important; }

.ui.RedButton.button, .ui.RedButton.buttons .button {
  background-color: #f5dcdf;
  color: inherit !important; }

.ui.RedButton.button:hover, .ui.RedButton.buttons:hover .button:hover,
.ui.RedButton.button:focus, .ui.RedButton.buttons:focus .button:focus {
  background-color: #e0b6bb;
  color: inherit !important; }

.ui.GreenButton.button i {
  color: #fff; }

.ui.GreenButton.button, .ui.GreenButton.buttons .button {
  background-color: #00ab53;
  color: #fff; }

.ui.GreenButton.button:hover, .ui.GreenButton.buttons:hover .button:hover,
.ui.GreenButton.button:focus, .ui.GreenButton.buttons:focus .button:focus {
  background-color: #00ab53;
  opacity: 0.9;
  color: #fff; }

.ui.TransButton.button i {
  color: #2fdaac; }

.ui.TransButton.button, .ui.TransButton.buttons .button {
  background-color: #fff;
  color: #2fdaac; }

.ui.TransButton.button:hover, .ui.TransButton.buttons:hover .button:hover,
.ui.TransButton.button:focus, .ui.TransButton.buttons:focus .button:focus {
  background-color: #f2f2f2;
  color: #25ca9e;
  opacity: 0.9; }

.ui.SideBarButton.button i {
  color: #fff !important; }

.ui.SideBarButton.button, .ui.SideBarButton.buttons .button {
  background-color: #e41e2e;
  color: #fff !important; }

.ui.SideBarButton.button:hover, .ui.SideBarButton.buttons:hover .button:hover,
.ui.SideBarButton.button:focus, .ui.SideBarButton.buttons:focus .button:focus {
  background-color: #f02d3d;
  color: #fff !important; }

.ui.button [class*=" uil-"]:before,
.ui.button [class^=uil-]:before {
  margin: 0 0.42857143em 0 -0.21428571em; }

.ui.SubmitButton.button {
  min-height: 1em;
  padding: 0.98571429em 1.5em 0.98571429em !important; }

.ui.SubmitButton.buttons .disabled.button,
.ui.SubmitButton.disabled.button,
.ui.SubmitButton.button:disabled,
.ui.SubmitButton.disabled.button:hover,
.ui.SubmitButton.disabled.active.button {
  opacity: .5 !important; }

.ui.SubmitButton.Isloading.button {
  padding: 0.88571429em 1.5em 0.78571429em !important; }

/*-----------------------------
  LOADER BUTTON CUSTOM
-------------------------------*/
.ui.SubmitButton.button .ui.loader,
.ui.SubmitButton.button .ui.loader {
  width: 1.28571429rem;
  height: 1.28571429rem;
  font-size: 0.5em !important; }

.ui.SubmitButton.button .ui.loader:before,
.ui.SubmitButton.button .ui.loader:after {
  width: 1.28571429rem;
  height: 1.28571429rem;
  margin: 0em 0em 0em -1.14285714rem; }

.ui.SubmitButton.button .ui.loader:before {
  position: absolute;
  content: '';
  top: 0%;
  left: 50%;
  width: 100%;
  height: 100%;
  border-radius: 500rem;
  border: 0.2em solid rgba(0, 0, 0, 0.1); }

.ui.SubmitButton.button .ui.loader:after {
  position: absolute;
  content: '';
  top: 0%;
  left: 50%;
  width: 100%;
  height: 100%;
  animation: loader 0.6s linear;
  animation-iteration-count: infinite;
  border-radius: 500rem;
  border-color: #fff transparent transparent;
  border-style: solid;
  border-width: 0.2em;
  box-shadow: 0px 0px 0px 1px transparent; }

.btnTransparent {
  background: transparent !important;
  margin: 0 !important; }

/*----------------------------------------------------------
  TABLE
-----------------------------------------------------------*/
.panel-table-header .ui.form .fields {
  align-items: center;
  margin: 0; }

.panel-table-header .ui.selection.dropdown {
  font-weight: normal; }

.ui.table {
  border-radius: 5px; }

.ui.table thead th {
  background: #f2f3f6;
  padding: 9px;
  color: #181a1f !important; }

/*----------------------------------------------------------
  FORMS
-----------------------------------------------------------*/
.ui.form input:not([type]),
.ui.form input[type=date],
.ui.form input[type=datetime-local],
.ui.form input[type=email],
.ui.form input[type=file],
.ui.form input[type=number],
.ui.form input[type=password],
.ui.form input[type=search],
.ui.form input[type=tel],
.ui.form input[type=text],
.ui.form input[type=time],
.ui.form input[type=url],
.ui.form textarea {
  font-family: "Nunito Sans", sans-serif;
  border: 1px solid #d6d6d8;
  font-size: 14px; }

.ui.form .field > label {
  display: block;
  color: inherit;
  font-weight: normal;
  text-transform: none;
  font-size: 14px; }

.ui.form .field .prompt.label {
  font-weight: normal; }

.ui.form .field .prompt.label.errorlabel {
  white-space: normal;
  background: #fff !important;
  border: none !important;
  color: #9f3a38 !important;
  padding-left: 0;
  font-size: 12px; }

.ui.form .field .prompt.label.errorlabel [class*=" uil-"]:before,
.ui.form .field .prompt.label.errorlabel [class^=uil-]:before {
  color: #9f3a38 !important;
  margin-top: -2px;
  margin-right: 3px;
  font-size: 12px !important;
  vertical-align: middle; }

.ui.disabled.input,
.ui.input:not(.disabled) input[disabled] {
  opacity: 0.8;
  background: #f2f2f2; }

.customLabel-error {
  color: #9f3a38 !important;
  padding-top: 3px;
  display: block; }

/*!
 * FullCalendar v3.4.0 Stylesheet
 * Docs & License: https://fullcalendar.io/
 * (c) 2017 Adam Shaw
 */
.fc-icon,
body .fc {
  font-size: 1em; }

.fc-button-group,
.fc-icon {
  display: inline-block; }

.fc-bg,
.fc-row .fc-bgevent-skeleton,
.fc-row .fc-highlight-skeleton {
  bottom: 0; }

.fc-icon,
.fc-unselectable {
  -khtml-user-select: none;
  -webkit-touch-callout: none; }

.fc {
  direction: ltr;
  text-align: left; }

.fc-rtl {
  text-align: right; }

.fc th,
.fc-basic-view td.fc-week-number,
.fc-icon,
.fc-toolbar {
  text-align: center; }

.fc-unthemed .fc-content,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-list-heading td,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-row,
.fc-unthemed tbody,
.fc-unthemed td,
.fc-unthemed th,
.fc-unthemed thead {
  border-color: #ddd; }

.fc-unthemed .fc-popover {
  background-color: #fff; }

.fc-unthemed .fc-divider,
.fc-unthemed .fc-list-heading td,
.fc-unthemed .fc-popover .fc-header {
  background: #eee; }

.fc-unthemed .fc-popover .fc-header .fc-close {
  color: #666; }

.fc-unthemed td.fc-today {
  background: #fff;
  color: #111; }

.fc-highlight {
  background: #bce8f1;
  opacity: 0.3; }

.fc-bgevent {
  background: #8fdf82;
  opacity: 0.3; }

.fc-nonbusiness {
  background: #d7d7d7; }

.fc-unthemed .fc-disabled-day {
  background: #d7d7d7;
  opacity: 0.3; }

.ui-widget .fc-disabled-day {
  background-image: none; }

.fc-icon {
  height: 1em;
  line-height: 1em;
  font-family: "Courier New", Courier, monospace;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.fc-icon:after {
  position: relative; }

.fc-icon-left-single-arrow:after {
  content: "\02039";
  font-weight: 500;
  font-size: 200%;
  top: -7%; }

.fc-icon-right-single-arrow:after {
  content: "\0203A";
  font-weight: 500;
  font-size: 200%;
  top: -7%; }

.fc-icon-left-double-arrow:after {
  content: "\000AB";
  font-size: 160%;
  top: -7%; }

.fc-icon-right-double-arrow:after {
  content: "\000BB";
  font-size: 160%;
  top: -7%; }

.fc-icon-left-triangle:after {
  content: "\25C4";
  font-size: 125%;
  top: 3%; }

.fc-icon-right-triangle:after {
  content: "\25BA";
  font-size: 125%;
  top: 3%; }

.fc-icon-down-triangle:after {
  content: "\25BC";
  font-size: 125%;
  top: 2%; }

.fc-icon-x:after {
  content: "\000D7";
  font-size: 200%;
  top: 6%; }

.fc button {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 6px 10px;
  white-space: nowrap;
  cursor: pointer; }

.fc button:focus {
  outline: none; }

.fc button::-moz-focus-inner {
  margin: 0;
  padding: 0; }

.fc-state-default {
  border: 1px solid #2c3e50;
  background-color: #2c3e50;
  color: #fff; }

.fc-state-default::first-letter,
.fc-today-button::first-letter {
  text-transform: uppercase; }

.fc-state-default.fc-corner-left {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; }

.fc-state-default.fc-corner-right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; }

.fc button .fc-icon {
  position: relative;
  top: -.05em;
  margin: 0 .2em;
  color: #fff;
  vertical-align: middle; }

.fc-state-active,
.fc-state-disabled,
.fc-state-down,
.fc-state-hover {
  background-color: #1a252f;
  outline: none;
  border: 1px solid #1a252f; }

.fc-state-hover {
  text-decoration: none; }

.fc-state-active,
.fc-state-down {
  background-color: #1a252f; }

.fc-state-disabled {
  cursor: default;
  background-image: none;
  opacity: .65;
  box-shadow: none; }

.fc-event.fc-draggable,
.fc-event[href],
.fc-popover .fc-header .fc-close,
a[data-goto] {
  cursor: pointer; }

.fc .fc-button-group > * {
  float: left;
  margin: 0 0 0 -1px; }

.fc .fc-button-group > :first-child {
  margin-left: 0; }

.fc-popover {
  position: absolute;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15); }

.fc-popover .fc-header {
  padding: 2px 4px; }

.fc-popover .fc-header .fc-title {
  margin: 0 2px; }

.fc-ltr .fc-popover .fc-header .fc-title,
.fc-rtl .fc-popover .fc-header .fc-close {
  float: left; }

.fc-ltr .fc-popover .fc-header .fc-close,
.fc-rtl .fc-popover .fc-header .fc-title {
  float: right; }

.fc-unthemed .fc-popover {
  border-width: 1px;
  border-style: solid; }

.fc-unthemed .fc-popover .fc-header .fc-close {
  font-size: .9em;
  margin-top: 2px; }

.fc-popover > .ui-widget-header + .ui-widget-content {
  border-top: 0; }

.fc-divider {
  border-style: solid;
  border-width: 1px; }

hr.fc-divider {
  height: 0;
  margin: 0;
  padding: 0 0 2px;
  border-width: 1px 0; }

.fc-bg table,
.fc-row .fc-bgevent-skeleton table,
.fc-row .fc-highlight-skeleton table {
  height: 100%; }

.fc-clear {
  clear: both; }

.fc-bg,
.fc-bgevent-skeleton,
.fc-helper-skeleton,
.fc-highlight-skeleton {
  position: absolute;
  top: 0;
  left: 0;
  right: 0; }

/*---------------------------------------- Table------------------------- */
.fc table {
  width: 100%;
  box-sizing: border-box;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0; }

.fc td,
.fc th {
  border-style: solid;
  border-width: 1px;
  padding: 0;
  vertical-align: top; }

.fc th {
  color: #111;
  padding: 5px;
  text-align: center !important; }

.fc th span {
  font-weight: 600 !important; }

.fc td a {
  color: inherit; }

.fc td.fc-today {
  border-style: double; }

a[data-goto]:hover {
  text-decoration: underline; }

.fc .fc-row {
  border-style: solid;
  border-width: 0; }

.fc-row table {
  border-left: 0 hidden transparent;
  border-right: 0 hidden transparent;
  border-bottom: 0 hidden transparent; }

.fc-row:first-child table {
  border-top: 0 hidden transparent; }

.fc-row {
  position: relative; }

.fc-row .fc-bg {
  z-index: 1; }

.fc-row .fc-bgevent-skeleton td,
.fc-row .fc-highlight-skeleton td {
  border-color: transparent; }

.fc-row .fc-bgevent-skeleton {
  z-index: 2; }

.fc-row .fc-highlight-skeleton {
  z-index: 3; }

.fc-row .fc-content-skeleton {
  position: relative;
  z-index: 4;
  padding-bottom: 2px; }

.fc-row .fc-helper-skeleton {
  z-index: 5; }

.fc-row .fc-content-skeleton td,
.fc-row .fc-helper-skeleton td {
  background: 0 0;
  border-color: transparent;
  border-bottom: 0; }

.fc-row .fc-content-skeleton tbody td,
.fc-row .fc-helper-skeleton tbody td {
  border-top: 0; }

.fc-scroller {
  -webkit-overflow-scrolling: touch; }

.fc-row.fc-rigid,
.fc-time-grid-event {
  overflow: hidden; }

.fc-scroller > .fc-day-grid,
.fc-scroller > .fc-time-grid {
  position: relative;
  width: 100%; }

.fc-event {
  position: relative;
  display: block;
  font-size: .85em;
  line-height: 1.3;
  border-radius: 3px;
  border: 1px solid #007cc2;
  font-weight: 400;
  text-align: center !important;
  padding: 5px !important; }

.fc-event,
.fc-event-dot {
  background-color: #007cc2;
  color: #fff !important; }

.fc-event,
.fc-event:hover,
.ui-widget .fc-event {
  color: #fff;
  text-decoration: none; }

.fc-not-allowed,
.fc-not-allowed .fc-event {
  cursor: not-allowed; }

.fc-event .fc-bg {
  z-index: 1;
  background: #fff;
  opacity: 0.25; }

.fc-event .fc-content {
  position: relative;
  z-index: 2; }

.fc-event .fc-resizer {
  position: absolute;
  z-index: 4;
  display: none; }

.fc-event.fc-allow-mouse-resize .fc-resizer,
.fc-event.fc-selected .fc-resizer {
  display: block; }

.fc-event.fc-selected .fc-resizer:before {
  content: "";
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-left: -20px;
  margin-top: -20px; }

.fc-event.fc-selected {
  z-index: 9999 !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); }

.fc-event.fc-selected.fc-dragging {
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.3); }

.fc-h-event.fc-selected:before {
  content: "";
  position: absolute;
  z-index: 3;
  top: -10px;
  bottom: -10px;
  left: 0;
  right: 0; }

.fc-ltr .fc-h-event.fc-not-start,
.fc-rtl .fc-h-event.fc-not-end {
  margin-left: 0;
  border-left-width: 0;
  padding-left: 1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.fc-ltr .fc-h-event.fc-not-end,
.fc-rtl .fc-h-event.fc-not-start {
  margin-right: 0;
  border-right-width: 0;
  padding-right: 1px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.fc-ltr .fc-h-event .fc-start-resizer,
.fc-rtl .fc-h-event .fc-end-resizer {
  cursor: w-resize;
  left: -1px; }

.fc-ltr .fc-h-event .fc-end-resizer,
.fc-rtl .fc-h-event .fc-start-resizer {
  cursor: e-resize;
  right: -1px; }

.fc-h-event.fc-allow-mouse-resize .fc-resizer {
  width: 7px;
  top: -1px;
  bottom: -1px; }

.fc-h-event.fc-selected .fc-resizer {
  border-radius: 4px;
  border-width: 1px;
  width: 6px;
  height: 6px;
  border-style: solid;
  border-color: inherit;
  background: #fff;
  top: 50%;
  margin-top: -4px; }

.fc-ltr .fc-h-event.fc-selected .fc-start-resizer,
.fc-rtl .fc-h-event.fc-selected .fc-end-resizer {
  margin-left: -4px; }

.fc-ltr .fc-h-event.fc-selected .fc-end-resizer,
.fc-rtl .fc-h-event.fc-selected .fc-start-resizer {
  margin-right: -4px; }

.fc-day-grid-event {
  margin: 1px 2px 0;
  padding: 0 1px; }

tr:first-child > td > .fc-day-grid-event {
  margin-top: 2px; }

.fc-day-grid-event.fc-selected:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  background: #000;
  opacity: 0.25; }

.fc-day-grid-event .fc-content {
  white-space: nowrap;
  overflow: hidden; }

.fc-day-grid-event .fc-time {
  font-weight: 700; }

.fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer,
.fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer {
  margin-left: -2px; }

.fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer,
.fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer {
  margin-right: -2px; }

a.fc-more {
  margin: 1px 3px;
  font-size: .85em;
  cursor: pointer;
  text-decoration: none; }

a.fc-more:hover {
  text-decoration: underline; }

.fc-limited {
  display: none; }

.fc-day-grid .fc-row {
  z-index: 1; }

.fc-more-popover {
  z-index: 2;
  width: 220px; }

.fc-more-popover .fc-event-container {
  padding: 10px; }

.fc-now-indicator {
  position: absolute;
  border: 0 solid red; }

.fc-unselectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent; }

/* ---------------------Header Calendar----------------------------------- */
.fc-toolbar.fc-header-toolbar {
  border-bottom: 1px solid #ddd;
  /*border-top: 3px solid #FFDC00;*/
  padding: 10px 20px; }

.fc-toolbar.fc-footer-toolbar {
  margin-top: 1em; }

.fc-toolbar .fc-left {
  float: left; }

.fc-toolbar .fc-right {
  float: right; }

.fc-toolbar .fc-center {
  display: inline-block; }

.fc .fc-toolbar > * > * {
  float: left;
  margin-left: 0.75em; }

.fc .fc-toolbar > * > :first-child {
  margin-left: 0; }

.fc-toolbar h2 {
  margin: 0;
  padding-top: 5px;
  font-weight: 500;
  font-size: 18px !important; }

.fc-toolbar button {
  position: relative; }

.fc-toolbar .fc-state-hover,
.fc-toolbar .ui-state-hover {
  z-index: 2; }

.fc-toolbar .fc-state-down {
  z-index: 3; }

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active {
  z-index: 4; }

.fc-toolbar button:focus {
  z-index: 5; }

/*------------------- Calendar Container -----------------------------------*/
.fc-view-container {
  padding: 20px; }

.fc-view-container *,
.fc-view-container:after,
.fc-view-container:before {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

.fc-view,
.fc-view > table {
  position: relative;
  z-index: 1; }

.fc-basicDay-view .fc-content-skeleton,
.fc-basicWeek-view .fc-content-skeleton {
  padding-bottom: 1em; }

.fc-basic-view .fc-body .fc-row {
  min-height: 4em; }

.fc-row.fc-rigid .fc-content-skeleton {
  position: absolute;
  top: 0;
  left: 0;
  right: 0; }

.fc-day-top.fc-other-month {
  opacity: 0.3; }

.fc-basic-view .fc-day-number,
.fc-basic-view .fc-week-number {
  padding: 2px; }

.fc-basic-view th.fc-day-number,
.fc-basic-view th.fc-week-number {
  padding: 0 2px; }

.fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
  float: right; }

.fc-rtl .fc-basic-view .fc-day-top .fc-day-number {
  float: left; }

.fc-ltr .fc-basic-view .fc-day-top .fc-week-number {
  float: left;
  border-radius: 0 0 3px; }

.fc-rtl .fc-basic-view .fc-day-top .fc-week-number {
  float: right;
  border-radius: 0 0 0 3px; }

.fc-basic-view .fc-day-top .fc-week-number {
  min-width: 1.5em;
  text-align: center;
  background-color: #f2f2f2;
  color: grey; }

.fc-basic-view td.fc-week-number > * {
  display: inline-block;
  min-width: 1.25em; }

.fc-agenda-view .fc-day-grid {
  position: relative;
  z-index: 2; }

.fc-agenda-view .fc-day-grid .fc-row {
  min-height: 3em; }

.fc-agenda-view .fc-day-grid .fc-row .fc-content-skeleton {
  padding-bottom: 1em; }

.fc .fc-axis {
  vertical-align: middle;
  padding: 0 4px;
  white-space: nowrap; }

.fc-ltr .fc-axis {
  text-align: right; }

.fc-rtl .fc-axis {
  text-align: left; }

.ui-widget td.fc-axis {
  font-weight: 400; }

.fc-time-grid,
.fc-time-grid-container {
  position: relative;
  z-index: 1; }

.fc-time-grid {
  min-height: 100%; }

.fc-time-grid table {
  border: 0 hidden transparent; }

.fc-time-grid > .fc-bg {
  z-index: 1; }

.fc-time-grid .fc-slats,
.fc-time-grid > hr {
  position: relative;
  z-index: 2; }

.fc-time-grid .fc-content-col {
  position: relative; }

.fc-time-grid .fc-content-skeleton {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0; }

.fc-time-grid .fc-business-container {
  position: relative;
  z-index: 1; }

.fc-time-grid .fc-bgevent-container {
  position: relative;
  z-index: 2; }

.fc-time-grid .fc-highlight-container {
  z-index: 3;
  position: relative; }

.fc-time-grid .fc-event-container {
  position: relative;
  z-index: 4; }

.fc-time-grid .fc-now-indicator-line {
  z-index: 5; }

.fc-time-grid .fc-helper-container {
  position: relative;
  z-index: 6; }

.fc-time-grid .fc-slats td {
  height: 1.5em;
  border-bottom: 0; }

.fc-time-grid .fc-slats .fc-minor td {
  border-top-style: dotted; }

.fc-time-grid .fc-slats .ui-widget-content {
  background: 0 0; }

.fc-time-grid .fc-highlight {
  position: absolute;
  left: 0;
  right: 0; }

.fc-ltr .fc-time-grid .fc-event-container {
  margin: 0 2.5% 0 2px; }

.fc-rtl .fc-time-grid .fc-event-container {
  margin: 0 2px 0 2.5%; }

.fc-time-grid .fc-bgevent,
.fc-time-grid .fc-event {
  position: absolute;
  z-index: 1; }

.fc-time-grid .fc-bgevent {
  left: 0;
  right: 0; }

.fc-v-event.fc-not-start {
  border-top-width: 0;
  padding-top: 1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.fc-v-event.fc-not-end {
  border-bottom-width: 0;
  padding-bottom: 1px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.fc-time-grid-event.fc-selected {
  overflow: visible; }

.fc-time-grid-event.fc-selected .fc-bg {
  display: none; }

.fc-time-grid-event .fc-content {
  overflow: hidden; }

.fc-time-grid-event .fc-time,
.fc-time-grid-event .fc-title {
  padding: 0 1px; }

.fc-time-grid-event .fc-time {
  font-size: .85em;
  white-space: nowrap; }

.fc-time-grid-event.fc-short .fc-content {
  white-space: nowrap; }

.fc-time-grid-event.fc-short .fc-time,
.fc-time-grid-event.fc-short .fc-title {
  display: inline-block;
  vertical-align: top; }

.fc-time-grid-event.fc-short .fc-time span {
  display: none; }

.fc-time-grid-event.fc-short .fc-time:before {
  content: attr(data-start); }

.fc-time-grid-event.fc-short .fc-time:after {
  content: " - "; }

.fc-time-grid-event.fc-short .fc-title {
  font-size: .85em;
  padding: 0; }

.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer {
  left: 0;
  right: 0;
  bottom: 0;
  height: 8px;
  overflow: hidden;
  line-height: 8px;
  font-size: 11px;
  font-family: monospace;
  text-align: center;
  cursor: s-resize; }

.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer:after {
  content: "="; }

.fc-time-grid-event.fc-selected .fc-resizer {
  border-radius: 5px;
  border-width: 1px;
  width: 8px;
  height: 8px;
  border-style: solid;
  border-color: inherit;
  background: #fff;
  left: 50%;
  margin-left: -5px;
  bottom: -5px; }

.fc-time-grid .fc-now-indicator-line {
  border-top-width: 1px;
  left: 0;
  right: 0; }

.fc-time-grid .fc-now-indicator-arrow {
  margin-top: -5px; }

.fc-ltr .fc-time-grid .fc-now-indicator-arrow {
  left: 0;
  border-width: 5px 0 5px 6px;
  border-top-color: transparent;
  border-bottom-color: transparent; }

.fc-rtl .fc-time-grid .fc-now-indicator-arrow {
  right: 0;
  border-width: 5px 6px 5px 0;
  border-top-color: transparent;
  border-bottom-color: transparent; }

.fc-event-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px; }

.fc-rtl .fc-list-view {
  direction: rtl; }

.fc-list-view {
  border-width: 1px;
  border-style: solid; }

.fc .fc-list-table {
  table-layout: auto; }

.fc-list-table td {
  border-width: 1px 0 0;
  padding: 8px 14px; }

.fc-list-table tr:first-child td {
  border-top-width: 0; }

.fc-list-heading {
  border-bottom-width: 1px; }

.fc-list-heading td {
  font-weight: 700; }

.fc-ltr .fc-list-heading-main {
  float: left; }

.fc-ltr .fc-list-heading-alt,
.fc-rtl .fc-list-heading-main {
  float: right; }

.fc-rtl .fc-list-heading-alt {
  float: left; }

.fc-list-item.fc-has-url {
  cursor: pointer; }

.fc-list-item:hover td {
  background-color: #f5f5f5; }

.fc-list-item-marker,
.fc-list-item-time {
  white-space: nowrap;
  width: 1px; }

.fc-ltr .fc-list-item-marker {
  padding-right: 0; }

.fc-rtl .fc-list-item-marker {
  padding-left: 0; }

.fc-list-item-title a {
  text-decoration: none;
  color: inherit; }

.fc-list-item-title a[href]:hover {
  text-decoration: underline; }

.fc-list-empty-wrap2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.fc-list-empty-wrap1 {
  width: 100%;
  height: 100%;
  display: table; }

.fc-list-empty {
  display: table-cell;
  vertical-align: middle;
  text-align: center; }

.fc-unthemed .fc-list-empty {
  background-color: #eee; }

div.filepicker {
  text-align: center;
  padding: 40px 5px;
  background-color: #fff;
  border-radius: 5px;
  border: 2px dotted silver; }

/*
.filepicker-file-icon
{
    position: relative;
    display: inline-block;
    margin: 1.5em 0 2.5em 0;
    padding-left: 45px;
    color: black;
}
.filepicker-file-icon::before
{
    position: absolute;
    top: -7px;
    left: 0;
    width: 29px;
    height: 34px;

    content: '';

    border: solid 2px #7F7F7F;
    border-radius: 2px;
}
.filepicker-file-icon::after
{
    font-size: 11px;
    line-height: 1.3;

    position: absolute;
    top: 9px;
    left: -4px;
    padding: 0 2px;
    content: 'file';
    content: attr(data-filetype);
    text-align: right;
    letter-spacing: 1px;
    text-transform: uppercase;

    color: #fff;
    background-color: #000;
}
.filepicker-file-icon .fileCorner
{
    position: absolute;
    top: -7px;
    left: 22px;
    width: 0;
    height: 0;
    border-width: 11px 0 0 11px;
    border-style: solid;
    border-color: white transparent transparent #920035;
}
*/
.dropzone .dz-preview {
  margin: 0 5px;
  text-align: middle;
  border: 1px solid #acacac;
  padding: 6px 6px 10px 6px;
  width: 150px;
  display: inline-block; }

.dz-details, .dz-progress, .dz-error-message, .dz-success-mark, .dz-error-mark {
  display: none !important; }

.dz-image {
  margin-bottom: 10px; }

.dz-image img {
  width: 100px;
  height: 80px;
  display: block;
  margin: 0 auto; }

.dz-remove {
  padding-bottom: 10px; }

.dropzone a.dz-remove, .dropzone-previews a.dz-remove {
  padding: 5px 10px;
  border-radius: 3px;
  color: #FFF !important;
  background: #ed2939 !important;
  cursor: pointer;
  width: 100%; }

.dz-default {
  font-size: 15px; }

.dropText {
  font-size: 15px; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  margin-left: -8px;
  position: absolute; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
  border-bottom-color: #aeaeae; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: #f0f0f0; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #d5ddea; }

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  bottom: 0;
  margin-bottom: -8px; }

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border-bottom: none;
  border-top-color: #fff; }

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  bottom: -1px;
  border-top-color: #aeaeae; }

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0; }

.react-datepicker {
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative; }

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px; }

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0; }

.react-datepicker--time-only .react-datepicker__time {
  border-radius: 0.3rem; }

.react-datepicker--time-only .react-datepicker__time-box {
  border-radius: 0.3rem; }

.react-datepicker__triangle {
  position: absolute;
  left: 50px; }

.react-datepicker-popper {
  z-index: 1; }

.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 10px; }

.react-datepicker-popper[data-placement="bottom-end"] .react-datepicker__triangle, .react-datepicker-popper[data-placement="top-end"] .react-datepicker__triangle {
  left: auto;
  right: 50px; }

.react-datepicker-popper[data-placement^="top"] {
  margin-bottom: 10px; }

.react-datepicker-popper[data-placement^="right"] {
  margin-left: 8px; }

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
  left: auto;
  right: 42px; }

.react-datepicker-popper[data-placement^="left"] {
  margin-right: 8px; }

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
  left: 42px;
  right: auto; }

.react-datepicker__header {
  text-align: center;
  background-color: #d5ddea;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  padding: 15px;
  position: relative; }

.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px; }

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px; }

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: rgba(0, 0, 0, 0.7);
  font-weight: bold;
  font-size: 1.2rem; }

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.react-datepicker__navigation {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden; }

.react-datepicker__navigation--previous {
  left: 15px;
  margin-top: 5px;
  background: url(https://img.icons8.com/windows/24/000000/back.png) no-repeat;
  width: 30px;
  height: 30px;
  border: none; }

.react-datepicker__navigation--previous:hover,
.react-datepicker__navigation--previous:focus {
  border-right-color: #b3b3b3;
  outline: none; }

.react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default; }

.react-datepicker__navigation--next {
  right: 10px;
  margin-top: 5px;
  /*border-left-color: #ccc;*/
  background: url(https://img.icons8.com/windows/24/000000/forward.png) no-repeat;
  width: 30px;
  height: 30px;
  border: none; }

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 80px; }

.react-datepicker__navigation--next:hover,
.react-datepicker__navigation--next:focus {
  border-left-color: #b3b3b3;
  outline: none; }

.react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default; }

.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: #ccc; }

.react-datepicker__navigation--years-previous:hover {
  border-top-color: #b3b3b3; }

.react-datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: #ccc; }

.react-datepicker__navigation--years-upcoming:hover {
  border-bottom-color: #b3b3b3; }

.react-datepicker__month-container {
  float: left; }

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center; }

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 2px; }

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left; }

.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block; }

.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block; }

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px; }

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: 85px; }

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
  -moz-appearance: textfield; }

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block; }

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 85px; }

.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -72px;
  top: 0; }

.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0px;
  padding-left: 0px;
  width: 100%;
  box-sizing: content-box; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
  white-space: nowrap; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #e41e2e;
  color: white;
  font-weight: bold; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #e41e2e; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent; }

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem; }

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer; }

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 50%;
  background-color: #f0f0f0; }

.react-datepicker__day-names {
  margin-top: 15px; }

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap; }

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 2rem;
  line-height: 2rem;
  text-align: center;
  margin: 0.166rem; }

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  border-radius: 0.3rem;
  background-color: #e41e2e;
  color: #fff; }

.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover,
.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter--in-selecting-range:hover,
.react-datepicker__quarter--in-range:hover {
  background-color: #e41e2e; }

.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
  color: #ccc;
  pointer-events: none; }

.react-datepicker__month--disabled:hover,
.react-datepicker__quarter--disabled:hover {
  cursor: default;
  background-color: transparent; }

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text {
  cursor: pointer; }

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  border-radius: 50%;
  background-color: #f0f0f0; }

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today {
  font-weight: bold; }

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff; }

.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover {
  background-color: #32be3f; }

.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1 {
  color: magenta; }

.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2 {
  color: green; }

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
  border-radius: 50%;
  background-color: #e41e2e;
  color: #fff !important; }

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover {
  border-radius: 50%;
  background-color: #e41e2e; }

.react-datepicker__day--selected:focus, .react-datepicker__day--in-selecting-range:focus, .react-datepicker__day--in-range:focus,
.react-datepicker__month-text--selected:focus,
.react-datepicker__month-text--in-selecting-range:focus,
.react-datepicker__month-text--in-range:focus,
.react-datepicker__quarter-text--selected:focus,
.react-datepicker__quarter-text--in-selecting-range:focus,
.react-datepicker__quarter-text--in-range:focus {
  background-color: #e41e2e;
  outline: none; }

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected {
  border-radius: 50%;
  background-color: #e41e2e;
  color: #fff !important; }

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover {
  background-color: #e41e2e;
  border-radius: 50%; }

.react-datepicker__day--keyboard-selected:focus,
.react-datepicker__month-text--keyboard-selected:focus,
.react-datepicker__quarter-text--keyboard-selected:focus {
  background-color: #e41e2e;
  outline: none; }

.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range {
  background-color: #e41e2e; }

.react-datepicker__month--selecting-range .react-datepicker__day--in-range, .react-datepicker__month--selecting-range
.react-datepicker__month-text--in-range, .react-datepicker__month--selecting-range
.react-datepicker__quarter-text--in-range {
  background-color: #f0f0f0;
  color: #000; }

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled {
  cursor: default;
  color: #ccc; }

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover {
  background-color: transparent; }

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #e41e2e; }

.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  background-color: #f0f0f0; }

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%; }

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem; }

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer; }

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3; }

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: #ccc;
  float: right;
  margin-left: 20px;
  top: 8px;
  position: relative;
  border-width: 0.45rem; }

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae; }

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer; }

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll; }

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc; }

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3; }

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3; }

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px; }

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0px 6px 0px 0px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle; }

.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #e41e2e;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "\00d7"; }

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left; }

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647; }

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem; }

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem; } }
.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem; }

.react-datepicker__portal .react-datepicker__navigation {
  border: 0.81rem solid transparent; }

.react-datepicker__portal .react-datepicker__navigation--previous {
  border-right-color: #ccc; }

.react-datepicker__portal .react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3; }

.react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default; }

.react-datepicker__portal .react-datepicker__navigation--next {
  border-left-color: #ccc; }

.react-datepicker__portal .react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3; }

.react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default; }

.react-datepicker__header__dropdown {
  padding-top: 20px; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: #d5ddea !important; }

.awssld__container figure, .awssld__content, .awssld__box {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }

.awssld__controls button, .awssld__bullets button {
  outline-color: 0;
  outline-style: none;
  outline-width: 0; }

.awssld {
  --organic-arrow-thickness: 3px;
  --organic-arrow-height: 20px;
  --slider-height-percentage: 60%;
  --loader-bar-color: #fa6d78;
  --loader-bar-height: 4px;
  --control-button-width: 10%;
  --control-button-height: 25%;
  --control-button-opacity: 0.5;
  --control-button-hover-opacity: 0.75;
  --control-button-background: transparent;
  --transition-bezier: cubic-bezier(0.5, 0.075, 0.25, 0.95);
  --slider-transition-duration: 575ms;
  --organic-arrow-color: #fff;
  --organic-arrow-border-radius: 0;
  --control-bullet-color: #6a6a6a;
  --control-bullet-active-color: #6a6a6a;
  --content-background-color: #2f2f2f; }

.awssld {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%; }

.awssld__wrapper {
  display: block;
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%; }

.awssld__container {
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: var(--slider-height-percentage); }

@media all and (max-width: 500px) {
  .awssld__container {
    padding-bottom: calc(var(--slider-height-percentage) * 1.25); } }
.awssld__startUp {
  background-color: red;
  height: 100%;
  width: 100%; }

.awssld__startUp > div {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }

.awssld__startUp img {
  width: 35%;
  height: auto; }

.awssld__content {
  background-color: var(--content-background-color);
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }

.awssld__content > img, .awssld__content > video {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0; }

.awssld__controls button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: var(--control-button-width);
  height: var(--control-button-height);
  position: absolute;
  z-index: 10;
  top: calc(50% - (0.5 * var(--control-button-height)));
  border: none;
  background-color: var(--control-button-background);
  color: #fff;
  cursor: pointer; }

.awssld__controls button .awssld__controls__arrow-left, .awssld__controls button .awssld__controls__arrow-right {
  opacity: var(--control-button-opacity); }

.awssld__controls button:hover .awssld__controls__arrow-left, .awssld__controls button:hover .awssld__controls__arrow-right {
  opacity: var(--control-button-opacity-hover); }

.awssld__controls--active .awssld__controls__arrow-left {
  opacity: var(--control-button-opacity-hover);
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0); }

.awssld__controls--active .awssld__controls__arrow-right {
  opacity: var(--control-button-opacity-hover);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0); }

.awssld__controls--hidden {
  display: none; }

@media all and (max-width: 520px) {
  .awssld__controls {
    visibility: hidden; } }
.awssld__bar {
  display: block;
  width: 100%;
  height: var(--loader-bar-height);
  background-color: var(--loader-bar-color);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101;
  -webkit-transition: -webkit-transform 3000ms var(--transition-bezier);
  transition: -webkit-transform 3000ms var(--transition-bezier);
  transition: transform 3000ms var(--transition-bezier);
  transition: transform 3000ms var(--transition-bezier), -webkit-transform 3000ms var(--transition-bezier);
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0); }

.awssld__bar--active {
  -webkit-transform: translate3d(-20%, 0, 0);
  transform: translate3d(-20%, 0, 0); }

.awssld__bar--end {
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.awssld__next {
  right: 0; }

.awssld__prev {
  left: 0; }

.awssld__box {
  z-index: 1;
  visibility: hidden; }

.awssld--animated {
  will-change: transform;
  visibility: visible; }

.awssld--animated-mobile {
  will-change: transform;
  -webkit-transition: -webkit-transform 325ms cubic-bezier(0.15, 0.65, 0.1, 1);
  transition: -webkit-transform 325ms cubic-bezier(0.15, 0.65, 0.1, 1);
  transition: transform 325ms cubic-bezier(0.15, 0.65, 0.1, 1);
  transition: transform 325ms cubic-bezier(0.15, 0.65, 0.1, 1), -webkit-transform 325ms cubic-bezier(0.15, 0.65, 0.1, 1); }

.awssld--active {
  visibility: visible;
  z-index: 2;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.awssld--moveRight, .awssld--moveLeft {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.awssld--moveRight {
  -webkit-animation: slideFromRight var(--slider-transition-duration) both var(--transition-bezier);
  animation: slideFromRight var(--slider-transition-duration) both var(--transition-bezier); }

.awssld--moveLeft {
  -webkit-animation: slideFromLeft var(--slider-transition-duration) both var(--transition-bezier);
  animation: slideFromLeft var(--slider-transition-duration) both var(--transition-bezier); }

.awssld--exit {
  z-index: 0; }

.awssld--exit.awssld--moveLeft {
  -webkit-animation: slideToLeft var(--slider-transition-duration) both var(--transition-bezier);
  animation: slideToLeft var(--slider-transition-duration) both var(--transition-bezier); }

.awssld--exit.awssld--moveRight {
  -webkit-animation: slideToRight var(--slider-transition-duration) both var(--transition-bezier);
  animation: slideToRight var(--slider-transition-duration) both var(--transition-bezier); }

.awssld--first .awssld__prev {
  visibility: hidden; }

.awssld--last .awssld__next {
  visibility: hidden; }

.awssld--fill-parent {
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  top: 0;
  left: 0; }

.awssld--fill-parent .awssld__container {
  height: 100%;
  padding: 0; }

.awssld__bullets {
  position: absolute;
  bottom: -40px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }

.awssld__bullets button {
  padding: 0;
  display: block;
  width: 16px;
  height: 16px;
  margin: 5px;
  border-radius: 50%;
  background: var(--control-bullet-color);
  text-indent: -9999px;
  overflow: hidden;
  cursor: pointer;
  border: none;
  -webkit-transition: background-color 0.175s ease-out, -webkit-transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45);
  transition: background-color 0.175s ease-out, -webkit-transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45);
  transition: transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45), background-color 0.175s ease-out;
  transition: transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45), background-color 0.175s ease-out, -webkit-transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45); }

.awssld__bullets button:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2); }

.awssld__bullets .awssld__bullets--loading {
  -webkit-transform: scale(1.2);
  transform: scale(1.2); }

.awssld__bullets .awssld__bullets--active {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  background: var(--control-bullet-active-color); }

.awssld__bullets .awssld__bullets--active:hover {
  -webkit-transform: scale(1.5);
  transform: scale(1.5); }

.awssld__controls__arrow-left, .awssld__controls__arrow-right {
  width: 100%;
  height: var(--organic-arrow-height);
  position: relative;
  display: block;
  -webkit-transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out 0.125s;
  transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out 0.125s;
  transition: transform 0.2s ease-out 0.125s, opacity 0.2s ease-out;
  transition: transform 0.2s ease-out 0.125s, opacity 0.2s ease-out, -webkit-transform 0.2s ease-out 0.125s; }

.awssld__controls__arrow-left:before, .awssld__controls__arrow-left:after, .awssld__controls__arrow-right:before, .awssld__controls__arrow-right:after {
  content: ' ';
  position: absolute;
  right: calc( 50% - ( 0.7071 * (var(--organic-arrow-height) + var(--organic-arrow-thickness)) ) / 2);
  height: 100%;
  border-radius: var(--organic-arrow-border-radius);
  width: var(--organic-arrow-thickness);
  background-color: var(--organic-arrow-color);
  -webkit-transition: background-color 0.15s ease-out, -webkit-transform 0.15s ease-out;
  transition: background-color 0.15s ease-out, -webkit-transform 0.15s ease-out;
  transition: transform 0.15s ease-out, background-color 0.15s ease-out;
  transition: transform 0.15s ease-out, background-color 0.15s ease-out, -webkit-transform 0.15s ease-out; }

.awssld__controls__arrow-left:before, .awssld__controls__arrow-right:before {
  -webkit-transform-origin: 100% 100% 0;
  transform-origin: 100% 100% 0;
  top: -50%;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.awssld__controls__arrow-left:after, .awssld__controls__arrow-right:after {
  -webkit-transform-origin: 100% 0% 0;
  transform-origin: 100% 0% 0;
  top: 50%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.awssld__controls__arrow-right--active {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0); }

.awssld__controls__arrow-right--active:after {
  -webkit-transform: rotate(90deg) translate3d(50%, 0, 0) !important;
  transform: rotate(90deg) translate3d(50%, 0, 0) !important; }

.awssld__controls__arrow-right--active:before {
  -webkit-transform: rotate(-90deg) translate3d(50%, 0, 0) !important;
  transform: rotate(-90deg) translate3d(50%, 0, 0) !important; }

.awssld__controls__arrow-left:before, .awssld__controls__arrow-left:after {
  right: auto;
  left: calc( 50% - ( 0.7071 * (var(--organic-arrow-height) + var(--organic-arrow-thickness)) ) / 2); }

.awssld__controls__arrow-left:before {
  -webkit-transform-origin: 0 100% 0;
  transform-origin: 0 100% 0;
  top: -50%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.awssld__controls__arrow-left:after {
  -webkit-transform-origin: 0 0 0;
  transform-origin: 0 0 0;
  top: 50%;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.awssld__controls__arrow-left--active {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0); }

.awssld__controls__arrow-left--active:after {
  -webkit-transform: rotate(-90deg) translate3d(-50%, 0, 0) !important;
  transform: rotate(-90deg) translate3d(-50%, 0, 0) !important; }

.awssld__controls__arrow-left--active:before {
  -webkit-transform: rotate(90deg) translate3d(-50%, 0, 0) !important;
  transform: rotate(90deg) translate3d(-50%, 0, 0) !important; }

.awssld__controls button:hover .awssld__controls__arrow-left:before {
  opacity: 1;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg); }

.awssld__controls button:hover .awssld__controls__arrow-left:after {
  opacity: 1;
  -webkit-transform: rotate(-30deg);
  transform: rotate(-30deg); }

.awssld__controls button:hover .awssld__controls__arrow-right:before {
  opacity: 1;
  -webkit-transform: rotate(-30deg);
  transform: rotate(-30deg); }

.awssld__controls button:hover .awssld__controls__arrow-right:after {
  opacity: 1;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg); }

.awssld__timer {
  --timer-delay: 2000ms;
  --timer-release: 200ms;
  --timer-height: 4px;
  --timer-background-color: rgba(0, 0, 0, 0.15);
  width: 100%;
  height: var(--timer-height);
  background-color: var(--timer-background-color);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%); }

.awssld__timer--animated {
  will-change: transform;
  -webkit-transition: -webkit-transform var(--timer-delay) linear;
  transition: -webkit-transform var(--timer-delay) linear;
  transition: transform var(--timer-delay) linear;
  transition: transform var(--timer-delay) linear, -webkit-transform var(--timer-delay) linear; }

.awssld__timer--run {
  -webkit-transform: translateX(0);
  transform: translateX(0); }

.awssld__timer--fast {
  -webkit-transition: -webkit-transform calc(var(--timer-release) / 2) linear;
  transition: -webkit-transform calc(var(--timer-release) / 2) linear;
  transition: transform calc(var(--timer-release) / 2) linear;
  transition: transform calc(var(--timer-release) / 2) linear, -webkit-transform calc(var(--timer-release) / 2) linear;
  -webkit-transform: translateX(-0.00001px);
  transform: translateX(-0.00001px); }

.awssld__timer--end {
  -webkit-transform: translateX(-0.0001px);
  transform: translateX(-0.0001px);
  -webkit-transition: -webkit-transform var(--timer-release) linear;
  transition: -webkit-transform var(--timer-release) linear;
  transition: transform var(--timer-release) linear;
  transition: transform var(--timer-release) linear, -webkit-transform var(--timer-release) linear; }

.awssld__timer--hidden {
  display: none; }

@-webkit-keyframes slideFromLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideFromLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@-webkit-keyframes slideFromRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideFromRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@-webkit-keyframes slideToLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }
@keyframes slideToLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }
@-webkit-keyframes slideToRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }
@keyframes slideToRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }
/*------------------------------------------------------------------
 OVERLAY MODAL
--------------------------------------------------------------------*/
.modalpanel-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 102 !important;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7); }

.modalpanel-container {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  flex-shrink: 0;
  width: 70%;
  margin: auto auto auto 0;
  height: calc(100% - 0px);
  background: #fff; }

.modalpanel-body {
  overflow: auto;
  padding: 20px;
  padding-bottom: 150px; }

.modalpanel-footer {
  margin-top: auto;
  flex: 0 0 auto 0;
  padding: 15px 20px;
  border-top: 1px solid #bfc4c7;
  background: #f9fafb; }

.modalpanel-footer.right {
  text-align: right; }

.modalpanel-footer-container {
  display: flex;
  justify-content: space-between; }

.modalpanel-footer-container > div:first-child {
  flex-basis: 40%; }

.modalpanel-footer-container > div:last-child {
  flex-basis: 58%; }

/*--------------------------------
    MEDIA QUERIES HAS TOP
---------------------------------*/
.modalpanel-wrapper.top {
  padding-top: 153px; }

@media screen and (min-width: 768px) {
  .modalpanel-wrapper.top {
    padding-top: 66px; } }
/*---------------------------
 MODAL POSITIONS
-----------------------------*/
.modalpanel-container.center {
  margin: 0 auto; }

.modalpanel-container.right {
  margin: 0 0 auto auto; }

/*---------------------------
 MODAL SIZES
-----------------------------*/
.modalpanel-container.tiny {
  width: 90%; }

.modalpanel-container.small {
  width: 90%; }

.modalpanel-container.medium {
  width: 90%; }

/*---------------------------
 OVERLAY MODAL header
-----------------------------*/
.modalpanel-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #bfc4c7;
  background: #f9fafb; }

.modalpanel-header h2 {
  font-weight: 800;
  text-transform: uppercase; }

.modalpanel-header > div {
  padding: 13px 20px; }

.modalpanel-header > div:first-child {
  flex-basis: 70%; }

.modalpanel-header > div:last-child {
  flex-basis: 30%;
  text-align: right; }

.modalpanel-header > div:last-child:hover {
  cursor: pointer; }

/*---------------------------
 MEDIA QUERIES
-----------------------------*/
@media screen and (min-width: 1024px) {
  .modalpanel-container.tiny {
    width: 40%; }

  .modalpanel-container.small {
    width: 50%; }

  .modalpanel-container.medium {
    width: 70%; } }
@media screen and (min-width: 1366px) {
  .modalpanel-container.tiny {
    width: 25%; }

  .modalpanel-container.small {
    width: 40%; }

  .modalpanel-container.medium {
    width: 60%; } }
@media screen and (min-width: 1680px) {
  .modalpanel-container.tiny {
    width: 18%; }

  .modalpanel-container.small {
    width: 28%; }

  .modalpanel-container.medium {
    width: 50%; } }
/*-----------------------------------------------------------------------------
 SPINNER
-------------------------------------------------------------------------------*/
.spinner-wrapper.relative {
  width: 100%;
  padding: 100px 0;
  position: relative;
  background: #fff; }

.spinner-wrapper h1 {
  font-size: 15px;
  font-weight: 600; }

.spinner-wrapper.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 101;
  background-color: rgba(0, 0, 0, 0.7); }

.spinner-wrapper.overlay .ui.loader:before {
  border-color: rgba(255, 255, 255, 0.15); }

.spinner-wrapper.overlay .ui.loader:after {
  border-color: #fff transparent transparent; }

.spinner-wrapper.overlay h1 {
  color: #fff;
  opacity: 0.9; }

.spinner-wrapper.overlay p {
  color: #fff;
  opacity: 0.7; }

.spinner-wrapper.white {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 103;
  background-color: #fff; }

/*-----------------------------------------------------------------------------
 ALERT MESSAGE
-------------------------------------------------------------------------------*/
.floating-alert-wrapper {
  position: fixed;
  z-index: 101 !important;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.floating-alert {
  margin: 30px auto 0 auto;
  display: flex;
  background: #fff;
  max-width: 350px;
  justify-content: space-around;
  padding: 15px; }

.floating-alert > div:first-child {
  padding-right: 10px;
  flex-basis: 10%; }

.floating-alert > div:last-child {
  flex-basis: 90%; }

.floating-alert > div:first-child i {
  margin-top: 8px;
  font-size: 29px; }

.floating-alert h1 {
  font-weight: 700; }

.floating-alert > div:last-child i {
  display: none; }

/* Info */
.floating-alert.info {
  border-left: 3px solid #4580a8; }

.floating-alert.info > div:first-child i {
  color: #4580a8; }

.floating-alert.info {
  background: #cce8f4; }

.floating-alert.info h1,
.floating-alert.info > div:last-child i {
  color: #4580a8; }

/* Success */
.floating-alert.success {
  border-left: 3px solid #00ab53; }

.floating-alert.success > div:first-child i {
  color: #00ab53; }

.floating-alert.success {
  background: #b6ebcb; }

.floating-alert.success h1,
.floating-alert.success > div:last-child i {
  color: #597054; }

/* Warning */
.floating-alert.warning {
  border-left: 3px solid #ffa301; }

.floating-alert.warning > div:first-child i {
  color: #a6772f; }

.floating-alert.warning {
  background: #f8f3d6; }

.floating-alert.warning h1,
.floating-alert.warning > div:last-child i {
  color: #a6772f; }

/* Error */
.floating-alert.error {
  border-left: 3px solid #b42f28; }

.floating-alert.error > div:first-child i {
  color: #b42f28; }

.floating-alert.error {
  background: #ebc8c4; }

.floating-alert.error h1,
.floating-alert.error > div:last-child i {
  color: #b42f28; }

/*-----------------------------------------
  Alert Confirmation
-----------------------------------------*/
.alert-content-wrapper {
  text-align: center; }

.alert-content-wrapper h1 {
  font-weight: 700;
  margin-bottom: 3px; }

.alert-content-wrapper img {
  margin: 0 auto; }

.ui.modal > .alert-footer-wrapper.actions {
  text-align: center; }

img.spinner_image {
  margin-bottom: 10px;
  max-width: 50px; }

/*-----------------------------------------
  BAGES ALERT MESSAGE
-----------------------------------------*/
/*-----------------------------------------
  ALERT MESSAGE
-----------------------------------------*/
.alert-message[disabled] {
  opacity: 0.5; }

.alert-message.left {
  display: flex;
  padding: 10px;
  border-radius: 5px; }

.alert-message.centerFlex {
  align-items: center; }

.alert-message.center {
  padding: 10px;
  border-radius: 5px;
  text-align: center; }

.alert-message h4 {
  font-weight: 700;
  margin-bottom: 5px; }

.alert-message p,
.alert-message span {
  font-size: 12px !important; }

.alert-message span {
  font-weight: 700; }

.alert-message i {
  margin-right: 10px; }

.alert-message.info {
  background: #dff0ff; }

.alert-message.info i {
  color: #2185d0; }

.alert-message.error {
  background: #FFF6F6; }

.alert-message.error i {
  color: #db2828; }

.alert-message.warning {
  background: #fffaf3; }

.alert-message.warning i {
  color: #c9ba9b; }

.alert-message.success {
  background: #bffbe9; }

.alert-message.success i {
  color: #33dca9; }

.alert-message.large {
  padding: 30px 10px; }

.alert-message.large i {
  font-size: 25px; }

.alert-message-ontable .alert-message.error {
  border: none !important; }

/*-----------------------------------------------------------------------------
 CUSTOM ALERT MESSAGE
-------------------------------------------------------------------------------*/
.custom-alert-wrapper {
  position: fixed;
  z-index: 101 !important;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.custom-alert {
  margin: 30px auto 0 auto;
  display: flex;
  background: #fff;
  max-width: 500px;
  justify-content: space-around;
  padding: 15px; }

.custom-alert > div:first-child {
  padding-right: 5px;
  flex-basis: 8%; }

.custom-alert > div:last-child {
  flex-basis: 92%; }

.custom-alert > div:first-child i {
  margin-top: 5px;
  font-size: 24px; }

.custom-alert h1 {
  font-weight: 800;
  font-size: 17px; }

.custom-alert > div:last-child i {
  display: none; }

.custom-alert-footer {
  margin-top: 6px;
  font-weight: 700 !important; }

/* Info */
.custom-alert.info {
  border-left: 3px solid #4580a8; }

.custom-alert.info > div:first-child i {
  color: #4580a8; }

.custom-alert.info {
  background: #cce8f4; }

.custom-alert.info h1,
.custom-alert.info > div:last-child i {
  color: #4580a8; }

/* Success */
.custom-alert.success {
  border-left: 3px solid #00ab53; }

.custom-alert.success > div:first-child i {
  color: #00ab53; }

.custom-alert.success {
  background: #b6ebcb; }

.custom-alert.success h1,
.custom-alert.success > div:last-child i {
  color: #597054; }

/* Warning */
.custom-alert.warning {
  border-left: 3px solid #ffa301; }

.custom-alert.warning > div:first-child i {
  color: #a6772f; }

.custom-alert.warning {
  background: #f8f3d6; }

.custom-alert.warning h1,
.custom-alert.warning > div:last-child i {
  color: #a6772f; }

/* Error */
.custom-alert.error {
  border-left: 3px solid #b42f28; }

.custom-alert.error > div:first-child i {
  color: #b42f28; }

.custom-alert.error {
  background: #ebc8c4; }

.custom-alert.error h1,
.custom-alert.error > div:last-child i {
  color: #b42f28; }

/*----------------------------------------------------------
  TABLE PANEL
-----------------------------------------------------------*/
.tableWrapper {
  margin-top: 5px; }

.panel-table {
  background: #fff;
  border-radius: 5px; }

.panel-table.custom {
  background: transparent !important; }

.panel-table-header {
  font-weight: 600;
  margin-bottom: 10px;
  padding: 10px 10px 0 10px; }

.panel-table-header > div {
  margin: 10px; }

.panel-table-body {
  padding: 5px 10px 10px 10px; }

.panel-table.custom .panel-table-body {
  padding: 0 !important; }

.panel-table-footer {
  border-top: 1px solid #d6d6d8;
  padding: 20px 20px; }

.panel-table-footer.text-right {
  text-align: right; }

.table-responsive {
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto; }

.table-responsive.overflow {
  overflow: visible; }

.ui.table td {
  vertical-align: middle !important; }

.ui.table td img {
  max-width: 30px;
  display: inline-block !important;
  vertical-align: middle !important;
  margin: 0 auto; }

.ui.table td img.big {
  max-width: 100px;
  display: inline-block !important;
  vertical-align: middle !important;
  margin: 0 auto; }

.ui.table td p {
  font-size: 11px;
  color: rgba(0, 0, 0, 0.7); }

.ui.table td.now-wrapp {
  white-space: nowrap; }

.ui.table.noBorder,
.ui.table.noBorder td {
  border: none !important; }

.profileTable {
  display: flex;
  align-items: center; }

.profileTable > div:first-child {
  padding-right: 5px; }

.profileTable img {
  max-width: 30px;
  border-radius: 50%; }

.profileTable h3,
.descriptionTable h3 {
  font-weight: 700;
  font-size: 14px !important; }

.tblButtonEqual {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.tblButtonEqual > div {
  padding: 3px 3px; }

.tblButtonEqual > div {
  width: 50%; }

.tblButtonEqual > div .button {
  width: 100% !important; }

@media screen and (min-width: 1024px) {
  .panel-table-header {
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px; }

  .panel-table-header > div {
    flex-basis: 49%;
    margin: 0; }

  .panel-table-header > div:last-child {
    text-align: right; } }
/*----------------------------------------------------------
  TABLE UI SELECT
-----------------------------------------------------------*/
.ui.table .ui.form .fields {
  margin: 0; }

.ui.table .ui.selection.dropdown .menu {
  overflow: visible; }

.ui.table .ui.form .fields > .field {
  padding-left: 0;
  padding-right: 0; }

/*----------------------------------------------------------
  STATUS
-----------------------------------------------------------*/
.table-icon-wrapper {
  padding: 2px 5px 2px 3px;
  border-radius: 3px; }

.table-icon-wrapper {
  font-size: 12px; }

.table-icon-wrapper [class*=" uil-"]:before,
.table-icon-wrapper [class^=uil-]:before {
  font-size: 15px; }

.table-icon-wrapper.positive {
  background: #33dca9;
  color: #fff; }

.table-icon-wrapper.positive i {
  color: #fff; }

.table-icon-wrapper.negative {
  background: #fc4b6c;
  color: #fff; }

.table-icon-wrapper.negative i {
  color: #fff; }

/*----------------------------------------------------------
  STATUS V2
-----------------------------------------------------------*/
.status-icon-wrapper span {
  padding: 4px 7px 4px 5px;
  border-radius: 3px;
  color: #344050; }

.status-icon-wrapper span {
  font-size: 11px;
  margin-left: 3px; }

.status-icon-wrapper [class*=" uil-"]:before,
.status-icon-wrapper [class^=uil-]:before {
  font-size: 20px; }

.status-icon-wrapper.positive span {
  background: #b6ebcb; }

.status-icon-wrapper.positive i {
  color: #33dca9; }

.status-icon-wrapper.warning span {
  background: #f7f3d8; }

.status-icon-wrapper.warning i {
  color: #93742c; }

.status-icon-wrapper.orange span {
  background: #f8f3d6; }

.status-icon-wrapper.orange i {
  color: #111; }

.status-icon-wrapper.negative span {
  background: #fadde2; }

.status-icon-wrapper.negative i {
  color: #fc4b6c; }

.statusWrapperDetails {
  margin: 5px 0;
  width: 100%; }

@media screen and (min-width: 1366px) {
  .statusWrapperDetails {
    margin: 5px 0;
    width: 30%; } }
/*----------------------------------------------------------
  SEARCH
-----------------------------------------------------------*/
.search-wrapper {
  width: 100%;
  padding: 10px;
  background: #fff;
  border-radius: 5px;
  margin: 10px 0; }

/*----------------------------------------------------------
  TABLE PAGINATIONS
-----------------------------------------------------------*/
.mypaginations ul li {
  display: inline-block; }

.mypaginations ul li a {
  font-weight: 700;
  margin: 0 3px;
  border-radius: 5px;
  border: 1px solid #d6d6d8;
  width: 20px;
  padding: 5px 10px;
  color: #181a1f !important; }

.mypaginations ul li a:hover {
  cursor: pointer; }

.mypaginations > div ul li a i {
  color: #111 !important; }

.mypaginations > div ul li a[disabled] i {
  color: #111 !important;
  opacity: 0.5; }

.mypaginations ul li a.is-current {
  background: #262626;
  border: 1px solid #262626;
  border-radius: 5px;
  font-weight: 700;
  color: #d9dadd !important; }

.pageinfotext {
  color: #181a1f !important;
  font-weight: 700; }

@media screen and (max-width: 767px) {
  .mypaginations > div {
    text-align: center;
    margin: 10px 0; } }
@media screen and (min-width: 768px) {
  .mypaginations {
    display: flex;
    justify-content: space-between; } }
/*------------------------------------------------------------------------------
  REACT DATE PICKER
------------------------------------------------------------------------------*/
.react-datepicker-wrapper {
  display: block !important;
  padding: 0 !important;
  border: 0 !important;
  max-width: 100% !important; }

/*------------------------------------------------------------------------------
  DROPZONE
------------------------------------------------------------------------------*/
.dropIcon [class*=" uil-"]:before,
.dropIcon [class^=uil-]:before {
  font-size: 25px !important; }

/*---------------------------------
  SCROLLBAR SIDEBAR
----------------------------------*/
/* width */
.sidebar-menu::-webkit-scrollbar {
  width: 7px; }

/* Track */
.sidebar-menu::-webkit-scrollbar-track {
  border-radius: 6px; }

/* Handle */
.sidebar-menu::-webkit-scrollbar-thumb {
  background: #777e89;
  border-radius: 6px; }

/* Handle on hover */
.sidebar-menu::-webkit-scrollbar-thumb:hover {
  background: #777e89; }

/*------------------------------------------------------------------
 SIDE PANEL SIDEBAR MENU
--------------------------------------------------------------------*/
.sidebarpanel-wrapper {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  /*background-color: rgba(0, 0, 0, .7);*/
  background: transparent; }

.sidebarpanel-container {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  flex-shrink: 0;
  width: 100%;
  margin: auto auto auto 0;
  background: #262626;
  height: calc(100% - 0px); }

.sidebarpanel-body {
  overflow: auto; }

.sidebarpanel-footer {
  margin-top: auto;
  flex: 0 0 auto 0;
  padding: 15px 10px; }

/*--------------------------------
    MEDIA QUERIES
---------------------------------*/
.sidebarpanel-wrapper {
  width: 61%;
  top: 18.2%; }

@media screen and (min-width: 768px) {
  .sidebarpanel-wrapper {
    width: 35%; } }
@media screen and (min-width: 1024px) {
  .sidebarpanel-wrapper {
    width: 22%; } }
@media screen and (min-width: 1366px) {
  .sidebarpanel-wrapper {
    width: 17%; } }
@media screen and (min-width: 1680px) {
  .sidebarpanel-wrapper {
    width: 12%; } }
/* ---------------------------------
  Sidebar Header Profile
-----------------------------------*/
.sidebarpanel-header {
  display: flex;
  align-items: center;
  padding: 20px 20px 15px 20px;
  border-bottom: 1px solid rgba(133, 133, 133, 0.2); }

.sidebarpanel-header p {
  color: #777e89;
  font-size: 12px; }

.sidebarpanel-header span {
  color: #777e89;
  font-size: 10px; }

.sidebarpanel-header h1 {
  font-weight: 600;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8); }

.sidebarpanel-header i {
  color: #21ba45;
  font-size: 8px; }

.sidebarpanel-header img {
  max-width: 40px;
  clip-path: circle();
  margin-right: 5px; }

/* ---------------------------------
  Sidebar Footer
-----------------------------------*/
.sidebarpanel-footer {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  justify-content: space-around;
  border-top: 1px solid rgba(133, 133, 133, 0.2); }

.sidebarpanel-footer i {
  color: #777e89; }

/* ---------------------------------
  Sidebar Menu
-----------------------------------*/
.sidebar-menu {
  padding-top: 15px;
  padding-bottom: 15px; }

.sidebar-menu .sidebar-dropdown > a:after,
.sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
.sidebar-menu ul li a i,
.sidebar-menu ul li a {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }

.sidebar-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0; }

.sidebar-menu a {
  text-decoration: none;
  color: #777e89; }

.sidebar-menu ul > li a i {
  /*background: #db2828;*/
  /*color: $SidebarColor!important;*/ }

.sidebar-menu ul li a,
.sidebar-menu ul li span,
.sidebar-menu ul li i {
  color: #777e89; }

.sidebar-menu ul li.active {
  background: #15181e;
  border-left: 2px solid #e41e2e; }

.sidebar-menu ul li.active a,
.sidebar-menu ul li.active span,
.sidebar-menu ul li.active i {
  color: rgba(255, 255, 255, 0.8) !important; }

.sidebar-menu ul li.active ul {
  background: #15181e; }

.sidebar-menu ul li a {
  display: inline-block;
  width: 100%;
  text-decoration: none;
  position: relative;
  padding: 10px 20px 10px 20px; }

.sidebar-menu ul > li > a:hover {
  background: #303742; }

.sidebar-menu ul li a i {
  margin-right: 10px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 17px;
  text-align: center;
  border-radius: 4px; }

.sidebar-menu ul li a:hover > i::before {
  display: inline-block;
  animation: swing ease-in-out 0.5s 1 alternate; }

.sidebar-menu .sidebar-dropdown > a:after {
  font-family: "Icons";
  font-weight: 900;
  content: "\f107";
  font-style: normal;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: 0 0;
  position: absolute;
  right: 15px;
  top: 14px; }

.sidebar-menu .sidebar-dropdown .sidebar-submenu ul {
  padding: 5px 0;
  background: #15181e; }

.sidebar-menu .sidebar-dropdown .sidebar-submenu li {
  /*padding-left: 25px;*/
  border-bottom: none; }

.sidebar-menu .sidebar-dropdown .sidebar-submenu li a {
  padding-left: 48px; }

.sidebar-menu .sidebar-submenu {
  display: none; }

.sidebar-menu .sidebar-submenu.active_dropdown {
  display: block; }

.sidebar-menu .sidebar-dropdown.active > a:after {
  transform: rotate(180deg);
  right: 17px; }

@media screen and (min-width: 768px) {
  .sidebarpanel-wrapper {
    display: none !important; } }
.box-checkbox {
  padding: 10px;
  border-radius: 5px;
  background: #dff0ff;
  margin-bottom: 10px; }

.box-checkbox .fields {
  margin: 0em !important; }

body.login {
  background: #f2f2f3; }

.login-wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 10px; }

.login-container {
  width: 95%; }

.login-logo-wrapper {
  padding: 10px 0 5px 0;
  text-align: center; }

.login-logo-wrapper img {
  max-width: 270px;
  margin: 0 auto; }

.login-panel {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  background: #fff;
  border-radius: 5px; }

.login-header {
  display: flex;
  align-items: center;
  padding: 20px 20px 20px 20px;
  border-bottom: 1px solid #bfc4c7; }

.login-header h1 {
  font-weight: 700; }

.login-header p {
  opacity: 0.8; }

.login-header img {
  max-width: 45px;
  border-radius: 50%;
  margin-right: 15px; }

.login-body {
  padding: 30px 20px; }

.login-footer {
  border-top: 1px solid #bfc4c7;
  padding: 20px;
  text-align: center; }

.login-footer p {
  margin-top: 10px; }

.login-footer a {
  text-decoration: underline; }

.login-footer > div {
  padding: 5px 0; }

.copyright-wrapper {
  text-align: center;
  padding-top: 10px; }

.copyright-wrapper p {
  font-size: 12px;
  opacity: 0.7; }

@media screen and (min-width: 768px) {
  .login-container {
    width: 50%; } }
@media screen and (min-width: 1024px) {
  .login-container {
    width: 30%; } }
@media screen and (min-width: 1366px) {
  .login-container {
    width: 25%; } }
@media screen and (min-width: 1680px) {
  .login-container {
    width: 18%; } }
.formWrapper {
  margin-top: 5px;
  width: 50%; }

.formWrapper .payment-panel {
  background: #fff;
  border-radius: 5px; }

.formButton {
  padding: 10px 0; }

/*-----------------------------------------------------------
 PANEL FORM
-----------------------------------------------------------*/
.panelForm {
  border-radius: 5px;
  background: #fff; }

.panelForm-header {
  border-bottom: 1px solid #d6d6d8;
  padding: 10px 20px; }

.panelForm-header h2 {
  font-weight: 700; }

.panelForm-body {
  padding: 20px; }

.panelForm-footer {
  border-top: 1px solid #d6d6d8;
  padding: 10px 20px; }

.panelForm-footer p {
  font-weight: 600; }

.buttonWrapper {
  margin-top: 15px; }

/*-----------------------------------------------------------
 PANEL
-----------------------------------------------------------*/
.panel {
  border-radius: 5px;
  background: #fff; }

.panel.hasTop {
  margin-top: 10px; }

.panel-header {
  padding: 10px 15px;
  border-bottom: 1px solid #d6d6d8; }

.panel-header h2 {
  font-weight: 700; }

.panel-body {
  padding: 20px; }

.panel-footer {
  border-top: 1px solid #d6d6d8;
  padding: 10px 20px; }

.buttonPageFooter {
  margin-top: 10px; }

/*-----------------------------
 Inside Panel Body
 .Layout customer information
-------------------------------*/
.layoutBox-wrapper {
  display: flex; }

.layoutBox-wrapper > div {
  flex-basis: 45%; }

.Notfound-wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 10px; }

.Notfound-container {
  width: 100%;
  margin: 0 auto;
  text-align: center; }

.Notfound-wrapper h1 {
  font-size: 80px;
  font-weight: 900;
  color: #e41e2e; }

.Notfound-wrapper h2 {
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 10px; }

.payment-wrapper {
  margin-top: 5px;
  width: 30%; }

.payment-panel {
  border: 1px solid #bfc4c7;
  border-radius: 5px;
  background: #fff; }

.payment-header {
  padding: 10px;
  text-transform: uppercase;
  font-weight: 800;
  border-bottom: 1px solid #bfc4c7; }

.payment-body {
  padding: 10px; }

.payment-list {
  display: flex;
  align-items: center;
  margin: 10px 0;
  background: #f7f7f7;
  border-radius: 5px;
  padding: 10px 15px;
  justify-content: space-between; }

.payment-list > div:first-child {
  flex-basis: 6%; }

.payment-list > div:last-child {
  flex: 1; }

.payment-logo {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.payment-logo img {
  max-width: 100px;
  text-align: right; }

.payment-list h2 {
  font-size: 16px;
  font-weight: 700; }

.payment-list p {
  opacity: .8; }

.payment-list:hover,
.payment-list.active {
  cursor: pointer;
  background-color: #dff0ff;
  color: #2185d0;
  -webkit-box-shadow: 0 0 0 1px #2185d0 inset, 0 0 0 0 transparent;
  box-shadow: 0 0 0 1px #2185d0 inset, 0 0 0 0 transparent; }

.payment-list.active i {
  color: #2185d0; }

.payment-footer {
  padding: 10px;
  border-top: 1px solid #bfc4c7; }

.paymentInfoAmount {
  text-align: center;
  padding: 5px 0 10px 0; }

.paymentInfoAmount h1 {
  font-weight: 800;
  font-size: 25px; }

.paymentInfoAmount p {
  font-style: italic;
  color: #db2828; }

.paymentCoditions {
  padding-top: 8px;
  text-align: center; }

/*------------------------------------------------------------------
 OVERLAY MODAL PAYMENT
--------------------------------------------------------------------*/
.modalPaymentPanel {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7); }

.modalPaymentPanel-container {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  flex-shrink: 0;
  width: 95%;
  height: calc(100% - 0px);
  background: #fff;
  margin: 0 auto; }

/*---------------------------
 OVERLAY MODAL body
-----------------------------*/
.modalPaymentPanel-body {
  overflow: auto;
  padding: 20px; }

/*---------------------------
 OVERLAY MODAL header
-----------------------------*/
.modalPaymentPanel-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #bfc4c7;
  background: #f9fafb; }

.modalPaymentPanel-header h2 {
  font-weight: 800;
  text-transform: uppercase; }

.modalPaymentPanel-header > div {
  padding: 13px 20px; }

.modalPaymentPanel-header > div:first-child {
  flex-basis: 80%; }

.modalPaymentPanel-header > div:last-child {
  flex-basis: 20%;
  text-align: right; }

.modalPaymentPanel-header > div:last-child:hover {
  cursor: pointer; }

/*---------------------------
 OVERLAY MODAL footer
-----------------------------*/
.modalPaymentPanel-footer {
  margin-top: auto;
  flex: 0 0 auto 0;
  padding: 15px 20px;
  border-top: 1px solid #bfc4c7;
  background: #f9fafb; }

.modalPaymentPanel-footer.right {
  text-align: right; }

.modalPaymentPanel-footer-container {
  display: flex;
  justify-content: space-between; }

.modalPaymentPanel-footer-container > div:first-child {
  flex-basis: 40%;
  white-space: nowrap; }

.modalPaymentPanel-footer-container > div:last-child {
  flex-basis: 58%; }

.modalPaymentPanel-footer-container span {
  padding-right: 5px;
  font-size: 15px;
  font-weight: 700; }

.modalPaymentPanel-footer-container > div:first-child .button {
  background: transparent !important; }

/*---------------------------
 MEDIA QUERIES
-----------------------------*/
@media screen and (min-width: 1024px) {
  .modalPaymentPanel-container {
    width: 70%; } }
@media screen and (min-width: 1366px) {
  .modalPaymentPanel-container {
    width: 50%; } }
@media screen and (min-width: 1680px) {
  .modalPaymentPanel-container {
    width: 30%; } }
/*-----------------------------------------------------------------------------------------
    HEADER
------------------------------------------------------------------------------------------*/
.appWrapper-header {
  background: #ffffff;
  border-bottom: 1px solid rgba(191, 196, 199, 0.5); }

.appWrapper-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.appWrapper-header .appWrapper-logo img {
  max-width: 200px;
  margin: 0 auto; }

.appWrapper-header ul li {
  text-align: left;
  display: inline-block;
  position: relative; }

.appWrapper-header ul li a {
  padding: 13px 20px;
  display: block; }

.appWrapper-header ul li a:hover,
.appWrapper-header ul li a:focus {
  cursor: pointer;
  color: inherit; }

.appWrapper-user-divider {
  display: flex;
  align-items: center; }

.appWrapper-user-divider .item-cart {
  display: inline-block;
  line-height: 0px;
  border-radius: 50%;
  background: #db2828; }

.appWrapper-user-divider .item-cart span {
  display: inline-block;
  padding-top: 50%;
  padding-bottom: 50%;
  margin-left: 6px;
  margin-right: 6px;
  color: #fff;
  font-size: 9px; }

.appWrapper-header [class*=" flaticon-"]:after,
.appWrapper-header [class*=" flaticon-"]:before,
.appWrapper-header [class^=flaticon-]:after,
.appWrapper-header [class^=flaticon-]:before {
  font-size: 32px;
  margin-right: 5px;
  vertical-align: middle; }

/*--------------------------------
    HEADER COLOR & BACKGROUND
---------------------------------*/
.appWrapper-user ul li {
  border-left: 1px solid rgba(191, 196, 199, 0.5); }

.appWrapper-user i,
.appWrapper-user a {
  color: #20252d; }

.appWrapper-user ul li:hover,
.appWrapper-user ul li:focus {
  background: #f1f3f6; }

.appWrapper-user ul li a:hover,
.appWrapper-user ul li a:focus {
  color: #20252d; }

.appWrapper-user ul li:hover a,
.appWrapper-user ul li:hover i {
  color: #20252d; }

/*--------------------------------
    Hamburger and Media Queries
---------------------------------*/
.appWrapper-logo.close .hamburger {
  display: block;
  padding-top: 15px;
  padding-left: 25px; }

.appWrapper-logo.open .hamburger {
  display: block;
  padding-top: 3px;
  padding-left: 35px; }

.appWrapper-logo.close .hamburger [class*=" flaticon-"]:after,
.appWrapper-logo.close .hamburger [class*=" flaticon-"]:before,
.appWrapper-logo.close .hamburger [class^=flaticon-]:after,
.appWrapper-logo.close .hamburger [class^=flaticon-]:before {
  font-size: 25px;
  margin: 0; }

.appWrapper-logo.open .hamburger [class*=" flaticon-"]:after,
.appWrapper-logo.open .hamburger [class*=" flaticon-"]:before,
.appWrapper-logo.open .hamburger [class^=flaticon-]:after,
.appWrapper-logo.open .hamburger [class^=flaticon-]:before {
  font-size: 18px;
  margin: 0; }

.appWrapper-logo img {
  display: none; }

@media screen and (min-width: 768px) {
  .appWrapper-logo img {
    display: block; }

  .appWrapper-logo.open .hamburger,
  .appWrapper-logo.close .hamburger {
    display: none; } }
/*--------------------------------
    Mobile Logo
---------------------------------*/
.appWrapper-mobile-logo {
  display: block;
  border-bottom: 1px solid #d6d6d8;
  padding: 10px 5px; }

.appWrapper-mobile-logo img {
  max-width: 200px;
  margin: 0 auto; }

@media screen and (min-width: 768px) {
  .appWrapper-mobile-logo {
    display: none; } }
/*-----------------------------------------------------------------------------------------
    MENU
------------------------------------------------------------------------------------------*/
.appWrapper-menu {
  background: #fff;
  width: 100%;
  border-bottom: 1px solid #bfc4c7; }

.appWrapper-menu ul li {
  text-align: center;
  display: inline-block;
  position: relative; }

.appWrapper-menu ul li a {
  padding: 6px 20px;
  display: block; }

.appWrapper-menu ul li a,
.appWrapper-menu ul li a span {
  color: #253750; }

.appWrapper-menu ul li a i {
  color: #253750; }

.appWrapper-menu ul li a:hover,
.appWrapper-menu ul li a:focus {
  cursor: pointer;
  color: inherit; }

.appWrapper-menu ul li span {
  display: block; }

.appWrapper-menu ul li.active,
.appWrapper-menu ul li:hover {
  background: #f1f3f6; }

.appWrapper-menu ul li.active a,
.appWrapper-menu ul li.active a span,
.appWrapper-menu ul li:hover a,
.appWrapper-menu ul li:hover a span {
  color: #e41e2e; }

.appWrapper-menu ul li.active a i,
.appWrapper-menu ul li:hover a i {
  color: #e41e2e; }

.appWrapper-menu {
  display: none; }

@media screen and (min-width: 768px) {
  .appWrapper-menu {
    display: block; } }
/* SLider */
.appWrapper-slider {
  position: relative;
  height: 500px; }

.awssld__wrapper {
  display: block;
  overflow: hidden;
  position: absolute !important;
  height: 500px !important;
  width: 100% !important; }

.awssld__bullets {
  display: none !important; }

.slider-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.4) !important; }

.slider-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: #fff;
  padding: 10px;
  text-align: center; }

.slider-text h2 {
  color: #e41e2e;
  font-size: 40px;
  font-weight: 700; }

.slider-text p {
  margin-bottom: 4px;
  color: #fff;
  font-size: 20px; }

/*------------------------------------------------------
 PAGES GRID CONTAINER
------------------------------------------------------*/
.appWrapper-data {
  padding: 30px 0 50px 0; }

.appWrapper-data.hasheader {
  padding: 0 0 50px 0;
  margin-top: -1%; }

.appWrapper-grid {
  width: 100%;
  margin: 0 auto;
  padding: 0 10px; }

@media screen and (min-width: 1920px) {
  .appWrapper-grid {
    width: 70%;
    margin: 0 auto; } }
/*------------------------------------------------------
 PAGE TITLE
------------------------------------------------------*/
.appWrapper-title {
  margin-bottom: 10px; }

.appWrapper-title.flex {
  padding: 10px 0 5px 0; }

.appWrapper-title.flex > div:last-child {
  text-align: left; }

.appWrapper-title h2 {
  font-weight: 800;
  font-size: 22px;
  color: #e41e2e; }

@media screen and (min-width: 768px) {
  .appWrapper-title.flex {
    display: flex;
    align-items: center;
    justify-content: space-between; }

  .appWrapper-title.flex > div {
    flex-basis: 50%; }

  .appWrapper-title.flex > div:last-child {
    text-align: right; } }
.appWrapper-intro {
  width: 100%;
  position: relative; }

.appWrapper-intro-container {
  background: #e41e2e;
  padding: 30px 30px;
  width: 100%;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  position: relative;
  z-index: 2; }

.appWrapper-intro h1 {
  margin-bottom: 10px;
  color: #fff;
  font-weight: 700; }

.appWrapper-intro p {
  color: rgba(255, 255, 255, 0.9);
  margin: 3px 0; }

.appWrapper-intro a {
  text-decoration: underline;
  font-weight: 700; }

.appWrapper-intro a:hover {
  cursor: pointer;
  color: inherit; }

@media screen and (min-width: 768px) {
  .appWrapper-intro-container {
    background: #e41e2e;
    padding: 30px 30px;
    width: 93%;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 300px;
    position: relative;
    z-index: 2; }

  .appWrapper-intro:after {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    background-repeat: no-repeat;
    background-size: 25%;
    background-position: 100% -30%;
    background-image: url("../images/bg-logo-circle-right.png"); }

  .appWrapper-intro-content:before {
    display: block;
    width: 20%;
    height: 100%;
    position: absolute;
    content: '';
    right: 5%;
    top: 0;
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: 94% 65%;
    background-image: url("../images/circle-svg.svg");
    z-index: 3; } }
@media screen and (min-width: 1024px) {
  .appWrapper-intro:after {
    background-size: 19%;
    background-position: 100% -30%; }

  .appWrapper-intro:before {
    background-size: 19%;
    background-position: 94% 65%; }

  .appWrapper-intro-content:before {
    width: 20%;
    right: 5%;
    background-size: 70%;
    background-position: 94% 65%; } }
@media screen and (min-width: 1366px) {
  .appWrapper-intro:after {
    background-size: 13%;
    background-position: 100% -30%; }

  .appWrapper-intro:before {
    background-size: 12%;
    background-position: 94% 65%; }

  .appWrapper-intro-content:before {
    width: 20%;
    right: 5%;
    background-size: 55%;
    background-position: 94% 65%; } }
/*-----------------------------------------------------------
 CART PAGE
-----------------------------------------------------------*/
.cartFormWrapper {
  width: 100%; }

@media screen and (min-width: 1920px) {
  .cartFormWrapper {
    width: 70%; } }
/*-----------------------------------------------------------
 Contact US
-----------------------------------------------------------*/
.contactWrapper > div {
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 15px; }

.contactDetails {
  display: flex;
  padding-bottom: 20px; }

.contactDetails:last-child {
  padding-bottom: 0; }

.contactDetails > div:last-child > p:first-child {
  font-weight: 700;
  color: #e41e2e; }

.contactDetails i {
  color: #e41e2e; }

@media screen and (min-width: 768px) {
  .contactWrapper {
    display: flex;
    justify-content: space-between; }

  .contactWrapper > div {
    flex-basis: 49%;
    margin-bottom: 0; } }
/*------------------------------------------------
 INFO STORE OPENING AND ADDRESS
------------------------------------------------*/
.appWrapper-address {
  background: #e41e2e;
  border-radius: 30px;
  padding: 20px;
  margin-bottom: 30px;
  position: relative; }

.info-wrapper:after {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: 116% -5%;
  background-image: url("../images/circle-svg.svg");
  z-index: 3; }

.info-wrapper > div {
  margin-bottom: 15px; }

.info-container {
  display: flex;
  justify-content: space-between; }

.info-container > div {
  flex-basis: 50%; }

.info-wrapper h2.info-title {
  color: #fff;
  text-transform: uppercase;
  font-weight: 800;
  margin-bottom: 15px; }

.info-container h2 {
  font-weight: 600;
  color: #fff; }

.info-wrapper h2.info-title [class^="flaticon-"]:before,
.info-wrapper h2.info-title [class*=" flaticon-"]:before,
.info-wrapper h2.info-title [class^="flaticon-"]:after,
.info-wrapper h2.info-title [class*=" flaticon-"]:after {
  font-size: 20px;
  font-weight: 800;
  margin-right: 15px; }

.hours-wrapper {
  display: flex;
  justify-content: space-between; }

.hours-wrapper > div {
  flex-basis: 50%; }

.infofooter-description {
  margin-bottom: 5px;
  color: #fff;
  margin-bottom: 20px; }

.appWrapper-address p.red {
  color: #fff;
  font-weight: 700; }

.appWrapper-address p.green {
  color: #fff;
  font-weight: 700; }

.info-wrapper p,
.info-wrapper a {
  margin: 5px 0;
  color: #fff; }

@media screen and (min-width: 768px) {
  .info-wrapper {
    display: flex;
    justify-content: space-between; }

  .info-wrapper > div {
    flex-basis: 50%;
    margin-bottom: 0; }

  .info-wrapper:after {
    background-size: 34%;
    background-position: 106% -24%; } }
@media screen and (min-width: 1024px) {
  .info-wrapper:after {
    background-size: 28%;
    background-position: 106% -24%; } }
@media screen and (min-width: 1366px) {
  .info-wrapper:after {
    background-size: 20%;
    background-position: 106% -24%; } }
/*------------------------------------------------
 FOOTER
------------------------------------------------*/
.appWrapper-footer {
  background: #262626; }

.appWrapper-footer p,
.appWrapper-footer a {
  color: #a6a6a6;
  margin: 5px 0; }

.appWrapper-footer a:hover {
  text-decoration: underline; }

/*------------------------------------------------
 SUBSCRIBER
------------------------------------------------*/
.appWrapper-subscribe {
  margin-bottom: 30px;
  border-bottom: 1px solid #464545;
  padding-bottom: 20px; }

.appWrapper-subscribe h2 {
  color: #fff;
  text-transform: uppercase;
  font-weight: 700; }

@media screen and (min-width: 1024px) {
  .appWrapper-subscribe-container {
    display: flex;
    align-items: center; }

  .appWrapper-subscribe-container > div {
    flex-basis: 50%; } }
/*------------------------------------------------
 INFO & LOGO FOOTER
------------------------------------------------*/
.appWrapper-info {
  padding: 50px 5px; }

.appWrapper-info-container > div {
  margin-bottom: 35px; }

.appWrapper-info-container > div:last-child {
  margin-bottom: 0; }

.companyInfo img {
  max-width: 200px;
  margin-bottom: 5px; }

.companyInfo i {
  font-size: 18px; }

.appWrapper-info-container h2 {
  color: #fff;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: 700; }

/*------------------------------------------------
 COPYRIGHT FOOTER
------------------------------------------------*/
.appWrapper-copyright {
  border-top: 1px solid #464545; }

.appWrapper-copyright-container {
  padding: 15px 5px;
  text-align: center; }

.appWrapper-copyright-container > div:last-child {
  text-align: center; }

.appWrapper-copyright-container > div:last-child span {
  margin: 0 10px; }

@media screen and (min-width: 768px) {
  /*------------------------------------------------
   INFO & LOGO FOOTER
  ------------------------------------------------*/
  .appWrapper-info-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }

  .appWrapper-info-container > div {
    flex-basis: 49.33%; }

  .appWrapper-info-container > div {
    margin-bottom: 20px; }

  /*------------------------------------------------
   COPYRIGHT FOOTER
  ------------------------------------------------*/
  .appWrapper-copyright-container {
    display: flex;
    justify-content: space-between;
    text-align: left; }

  .appWrapper-copyright-container > div {
    flex-basis: 50%; }

  .appWrapper-copyright-container > div:last-child {
    text-align: right; }

  .appWrapper-copyright-container > div:last-child span {
    margin-left: 10px; } }
@media screen and (min-width: 1024px) {
  .appWrapper-info-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap; }

  .appWrapper-info-container > div {
    flex-basis: 24.33%; }

  .appWrapper-info-container > div {
    margin-bottom: 0; } }


