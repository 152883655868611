/*------------------------------------------------------------------
 SIDE PANEL SIDEBAR MENU
--------------------------------------------------------------------*/
.sidebarpanel-wrapper{
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $OverlayZindex;
  /*background-color: rgba(0, 0, 0, .7);*/
  background: transparent;
}
.sidebarpanel-container{
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  flex-shrink: 0;
  width: 100%;
  margin:auto auto auto 0;
  background: $SidebarBg;
  height: calc(100% - 0px);
}

.sidebarpanel-body{
  overflow: auto;
}

.sidebarpanel-footer{
  margin-top: auto;
  flex: 0 0 auto 0;
  padding:15px 10px;
}


/*--------------------------------
    MEDIA QUERIES
---------------------------------*/
.sidebarpanel-wrapper{
   width: $TopSideWidth;
   top: $TopSidebar_mobile;
}
@media screen and (min-width: 768px) {
  .sidebarpanel-wrapper{
     width: $TopSideWidth_768;
  }
}
@media screen and (min-width: 1024px) {
  .sidebarpanel-wrapper{
     width: $TopSideWidth_1024;
  }
}
@media screen and (min-width: 1366px) {
  .sidebarpanel-wrapper{
     width: $TopSideWidth_1366;
  }
}
@media screen and (min-width: 1680px) {
  .sidebarpanel-wrapper{
     width: $TopSideWidth_1680;
  }
}



/* ---------------------------------
  Sidebar Header Profile
-----------------------------------*/
.sidebarpanel-header{
  display: flex;
  align-items: center;
  padding: 20px 20px 15px 20px;
  border-bottom: 1px solid $SidebarBorder;
}
.sidebarpanel-header p{
  color: $SidebarColor;
  font-size: 12px;
}
.sidebarpanel-header span{
  color: $SidebarColor;
  font-size: 10px;
}
.sidebarpanel-header h1{
  font-weight: 600;
  font-size: 14px;
  color: $SidebarActiveColor;
}

.sidebarpanel-header i{
  color: $GreenColor;
  font-size: 8px;
}
.sidebarpanel-header img{
  max-width: 40px;
  clip-path: circle();
  margin-right: 5px;
}





/* ---------------------------------
  Sidebar Footer
-----------------------------------*/
.sidebarpanel-footer{
  display: flex;
  align-items: center;
  padding:10px 20px;
  justify-content: space-around;
  border-top: 1px solid $SidebarBorder;
}
.sidebarpanel-footer i{
  color: $SidebarColor;
}


/* ---------------------------------
  Sidebar Menu
-----------------------------------*/
.sidebar-menu{
  padding-top: 15px;
  padding-bottom: 15px;
}
.sidebar-menu .sidebar-dropdown > a:after,
.sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
.sidebar-menu ul li a i,
.sidebar-menu ul li a{
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.sidebar-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar-menu a {
  text-decoration: none;
  color: $SidebarColor;
}
.sidebar-menu ul >li a i{
   /*background: #db2828;*/
   /*color: $SidebarColor!important;*/
}

.sidebar-menu ul li a,
.sidebar-menu ul li span,
.sidebar-menu ul li i{
  color: $SidebarColor;
}

.sidebar-menu ul li.active{
   background: $SidebarActiveBg;
   border-left: 2px solid $SidebarActiveBorder;
}

.sidebar-menu ul li.active a,
.sidebar-menu ul li.active span,
.sidebar-menu ul li.active i{
   color: $SidebarActiveColor!important;
}

.sidebar-menu ul li.active ul{
   background: $SidebarDropdownBg;
}


.sidebar-menu ul li a {
  display: inline-block;
  width: 100%;
  text-decoration: none;
  position: relative;
  padding: 10px 20px 10px 20px;
}

.sidebar-menu ul >li >a:hover{
  background: $SidebarLinkHoverBg;
}


.sidebar-menu ul li a i {
  margin-right: 10px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 17px;
  text-align: center;
  border-radius: 4px;
}

.sidebar-menu ul li a:hover > i::before {
  display: inline-block;
  animation: swing ease-in-out 0.5s 1 alternate;
}

.sidebar-menu .sidebar-dropdown > a:after {
  font-family: "Icons";
  font-weight: 900;
  content: "\f107";
  font-style: normal;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: 0 0;
  position: absolute;
  right: 15px;
  top: 14px;
}

.sidebar-menu .sidebar-dropdown .sidebar-submenu ul {
  padding: 5px 0;
  background: $SidebarDropdownBg;
}

.sidebar-menu .sidebar-dropdown .sidebar-submenu li {
  /*padding-left: 25px;*/
  border-bottom: none;
}
.sidebar-menu .sidebar-dropdown .sidebar-submenu li a{
  padding-left: 48px;
}

.sidebar-menu .sidebar-submenu{
  display: none;
}
.sidebar-menu .sidebar-submenu.active_dropdown{
  display: block;
}

.sidebar-menu .sidebar-dropdown.active > a:after {
  transform: rotate(180deg);
  right: 17px;
}

@media screen and (min-width: 768px) {
  .sidebarpanel-wrapper{
    display: none!important;
  }
}
