/* SLider */
.appWrapper-slider{
  position: relative;
  height: 500px;
}
.awssld__wrapper {
  display: block;
  overflow: hidden;
  position: absolute!important;
  height: 500px!important;
  width: 100%!important;
}
.awssld__bullets{
  display: none!important;
}
.slider-wrapper{
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  background-color: rgba(0,0,0,0.4)!important;
}
.slider-container{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  z-index: 2;
  color: #fff;
  padding: 10px;
  text-align: center;
}
.slider-text h2{
  color: $PrimaryColor;
  font-size: 40px;
  font-weight: 700;
}
.slider-text p{
  margin-bottom: 4px;
  color: #fff;
  font-size: 20px;
}
