.formWrapper{
  margin-top: 5px;
  width: 50%;
}
.formWrapper .payment-panel{
  background: #fff;
  border-radius: 5px;
}
.formButton{
  padding:10px 0;
}


/*-----------------------------------------------------------
 PANEL FORM
-----------------------------------------------------------*/
.panelForm{
  border-radius: 5px;
  background: #fff;
}
.panelForm-header{
  border-bottom: 1px solid $BorderColor;
  padding:10px 20px;
}
.panelForm-header h2{
  font-weight: 700;
}
.panelForm-body{
  padding:20px;
}
.panelForm-footer{
  border-top: 1px solid $BorderColor;
  padding:10px 20px;
}
.panelForm-footer p{
  font-weight: 600;
}
.buttonWrapper{
  margin-top: 15px;
}
