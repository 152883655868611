div.filepicker {
    text-align: center;
    padding:40px 5px;
    background-color: #fff;
    border-radius: 5px;
    border: 2px dotted silver;
}

/*
.filepicker-file-icon
{
    position: relative;
    display: inline-block;
    margin: 1.5em 0 2.5em 0;
    padding-left: 45px;
    color: black;
}
.filepicker-file-icon::before
{
    position: absolute;
    top: -7px;
    left: 0;
    width: 29px;
    height: 34px;

    content: '';

    border: solid 2px #7F7F7F;
    border-radius: 2px;
}
.filepicker-file-icon::after
{
    font-size: 11px;
    line-height: 1.3;

    position: absolute;
    top: 9px;
    left: -4px;
    padding: 0 2px;
    content: 'file';
    content: attr(data-filetype);
    text-align: right;
    letter-spacing: 1px;
    text-transform: uppercase;

    color: #fff;
    background-color: #000;
}
.filepicker-file-icon .fileCorner
{
    position: absolute;
    top: -7px;
    left: 22px;
    width: 0;
    height: 0;
    border-width: 11px 0 0 11px;
    border-style: solid;
    border-color: white transparent transparent #920035;
}
*/

.dropzone .dz-preview {
    margin: 0 5px;
    text-align: middle;
    border: 1px solid #acacac;
    padding: 6px 6px 10px 6px;
    width: 150px;
    display: inline-block;
}


.dz-details,.dz-progress,.dz-error-message,.dz-success-mark,.dz-error-mark{
    display: none!important;
}
.dz-image{
    margin-bottom: 10px;
}
.dz-image img{
    width: 100px;
    height: 80px;
    display: block;
    margin:0 auto;
}
.dz-remove{
    padding-bottom: 10px;
}
.dropzone a.dz-remove, .dropzone-previews a.dz-remove {
    padding: 5px 10px;
    border-radius: 3px;
    color: #FFF!important;
    background: #ed2939!important;
    cursor: pointer;
    width: 100%;
}
.dz-default{
    font-size: 15px;
}
.dropText{
    font-size: 15px;
}
