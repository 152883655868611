
.payment-wrapper{
  margin-top: 5px;
  width: 30%;
}

.payment-panel{
  border:1px solid $BorderColorDark;
  border-radius: 5px;
  background: #fff;
}
.payment-header{
  padding:10px;
  text-transform: uppercase;
  font-weight: 800;
  border-bottom: 1px solid $BorderColorDark;
}

.payment-body{
  padding: 10px;
}

.payment-list{
  display: flex;
  align-items: center;
  margin:10px 0;
  background: #f7f7f7;
  border-radius: 5px;
  padding:10px 15px;
  justify-content: space-between;
}
.payment-list>div:first-child{
  flex-basis: 6%;
}
.payment-list>div:last-child{
  flex:1;
}

.payment-logo{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.payment-logo img{
  max-width: 100px;
  text-align: right;
}
.payment-list h2{
  font-size: 16px;
  font-weight: 700;
}
.payment-list p{
  opacity: .8;
}

.payment-list:hover,
.payment-list.active{
  cursor: pointer;
  background-color: #dff0ff;
  color: #2185d0;
  -webkit-box-shadow: 0 0 0 1px #2185d0 inset, 0 0 0 0 transparent;
  box-shadow: 0 0 0 1px #2185d0 inset, 0 0 0 0 transparent;
}
.payment-list.active i{
  color: #2185d0;
}

.payment-footer{
  padding:10px;
  border-top: 1px solid $BorderColorDark;
}

.paymentInfoAmount{
  text-align: center;
  padding:5px 0 10px 0;
}
.paymentInfoAmount h1{
  font-weight: 800;
  font-size: 25px;
}
.paymentInfoAmount p{
  font-style: italic;
  color: #db2828;
}

.paymentCoditions{
  padding-top: 8px;
  text-align: center;
}



/*------------------------------------------------------------------
 OVERLAY MODAL PAYMENT
--------------------------------------------------------------------*/
.modalPaymentPanel{
  position:fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $OverlayZindex;
  width: 100%;
  background-color: rgba(0, 0, 0, .7);
}

.modalPaymentPanel-container{
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  flex-shrink: 0;
  width: 95%;
  height: calc(100% - 0px);
  background: #fff;
  margin:0 auto;
}


/*---------------------------
 OVERLAY MODAL body
-----------------------------*/

.modalPaymentPanel-body{
  overflow: auto;
  padding: 20px;
}



/*---------------------------
 OVERLAY MODAL header
-----------------------------*/
.modalPaymentPanel-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $BorderColorDark;
  background: #f9fafb;
}
.modalPaymentPanel-header h2{
  font-weight: 800;
  text-transform: uppercase;
}


.modalPaymentPanel-header>div{
  padding:13px 20px;
}
.modalPaymentPanel-header>div:first-child{
  flex-basis: 80%;
}
.modalPaymentPanel-header>div:last-child{
  flex-basis: 20%;
  text-align: right;
}
.modalPaymentPanel-header>div:last-child:hover{
  cursor: pointer;
}


/*---------------------------
 OVERLAY MODAL footer
-----------------------------*/
.modalPaymentPanel-footer{
  margin-top: auto;
  flex: 0 0 auto 0;
  padding:15px 20px;
  border-top: 1px solid $BorderColorDark;
  background: #f9fafb;
}

.modalPaymentPanel-footer.right{
  text-align: right;
}
.modalPaymentPanel-footer-container{
  display: flex;
  justify-content: space-between;
}
.modalPaymentPanel-footer-container>div:first-child{
  flex-basis: 40%;
  white-space: nowrap;
}
.modalPaymentPanel-footer-container>div:last-child{
  flex-basis: 58%;
}
.modalPaymentPanel-footer-container span{
  padding-right: 5px;
  font-size: 15px;
  font-weight: 700;
}
.modalPaymentPanel-footer-container>div:first-child .button{
  background: transparent!important;
}



/*---------------------------
 MEDIA QUERIES
-----------------------------*/
@media screen and (min-width: 1024px) {
  .modalPaymentPanel-container{
    width: 70%;
  }
}
@media screen and (min-width: 1366px) {
  .modalPaymentPanel-container{
    width: 50%;
  }
}
@media screen and (min-width: 1680px) {
  .modalPaymentPanel-container{
    width: 30%;
  }
}
