@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kodchasan:wght@500;600;700&display=swap');
/* ---------------------------------
 Media Queries
 $FontFamily: 'Nunito Sans', sans-serif;
 @media screen and (min-width: 768px) {
 }
 768px
 1024px
 1366px
 1440px
 1680px
 1920px
---------------------------------- */

$FontFamily: 'Nunito Sans', sans-serif;
$Color: #181a1f;
$HeadingColor:#181a1f;
$FontSize: 14px;
$BackgrondColor: #f2f2f3;


/* ---------------------------------
 COLOR
---------------------------------- */
$PrimaryColor: #e41e2e;
$GreenColor: #21ba45;
$WhiteColorOnDark: #6b7185;

/* ---------------------------------
 BACKGROUND COLOR
---------------------------------- */
$BgOrange: #f2711c;
$BgYellow: #fbbd08;
$BgOlive: #b5cc18;
$BgGreen: #21ba45;
$BgBlue: #2185d0;
$BgPink: #e03997;
$BgPurple: #a333c8;
$BgViolet: #6435c9;
$BgRed: #db2828;

$BgGreenHover: #139732;
$BgRedHover: #b71616;


$ButtonRed: #f5dcdf;
$ButtonRedHover: #e0b6bb;

$GreenButton:#b6ebcb;
$GreenButtonColor:#00ab53;
$GreenHoverButton:#9be3b7;
/* ---------------------------------
 BORDER
---------------------------------- */
$BorderColor: #d6d6d8;
$BorderColorDark: #bfc4c7;
//$BoxShadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
$BoxShadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
$BoxShadowBorder: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;



/* ---------------------------------
   SCROLLBAR
---------------------------------- */
$ScrollBarBg: #20252d;
$ScrollBarBgThumb: #777e89;




/* ---------------------------------
   THEME COLOR AND BACKGROUND HEADER
---------------------------------- */
$HeaderBgColor: #ffffff;
$HeaderBgColorHover: #f1f3f6;
$HeaderColor: #20252d;
$HeaderBorderColor: #bfc4c7;
$HeaderBOrderColorFade: rgba(191,196,199,0.5);
$HeaderColorHover: #20252d;



/* ---------------------------------
 Z-INDEX
---------------------------------- */
$TopHeaderZindex: 100;
$OverlayZindex: 101;
$ZindexPriority: 102;
$ZindexTopPriority: 103;


/* ---------------------------------
  CONFIG WITH AND MARGIN
---------------------------------- */
$TopSideWidth: 61%;
$TopSideWidth_768: 35%;
$TopSideWidth_1024: 22%;
$TopSideWidth_1366: 17%;
$TopSideWidth_1680: 12%;


$TopMargin: 66px;
$TopMarginOnMobile: 153px;


/* ---------------------------------
   SIDEBAR THEME
---------------------------------- */
$SidebarBg: #262626;
$SidebarColor:rgb(119, 126, 137);
$SidebarDropdownBg:#15181e;
$SidebarActiveBg:#15181e;
$SidebarActiveColor:rgba(255, 255, 255, 0.8);
$SidebarActiveBorder:#e41e2e;
$SidebarLinkHoverBg:#303742;
$SidebarBorder: rgba(133, 133, 133, 0.2);
$SidebarButton: #e41e2e;
$SidebarButtonHover: #f02d3d;


/* ---------------------------------
   MENU THEME
---------------------------------- */
$MenuBg: #fff;
$MenuColor: #253750;
$MenuBorderColor: #bfc4c7;
$MenuIconColor: #253750;
$MenuActiveBg:#f1f3f6;
$MenuActiveColor:#e41e2e;
$MenuActiveIconColor:#e41e2e;

/* ---------------------------------
   FOOTER THEME
---------------------------------- */
$FooterBg: #262626;
$FooterBorder: #464545;
$FooterColor: #a6a6a6;

/* ---------------------------------
   THEME SIDEBAR WIDTH
---------------------------------- */
$TopSidebar_mobile: 18.2%;
$SidebarWidth: 61%;
$SidebarWidth_768: 35%;
$SidebarWidth_1024: 22%;
$SidebarWidth_1366: 17%;
$SidebarWidth_1680: 12%;


/* ---------------------------------
   IMPORT STYLE
---------------------------------- */
@import './reset';
@import './semantic';

@import './framework/calendar';
@import './framework/dropzone';
@import './framework/react-datepicker';
@import './framework/awesome-slider';


@import './modal';
@import './alert';
@import './table';
@import './framework';
@import './scrollbar';
@import './sidebar';
@import './utility';
@import './layout';
@import './login';
@import './form';
@import './panel';
@import './404';

@import './collections/payment';
@import './components/menu';
@import './components/slider';
@import './components/pages';
@import './components/footer';
