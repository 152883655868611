/*-----------------------------------------------------------------------------
 SPINNER
-------------------------------------------------------------------------------*/
.spinner-wrapper.relative{
  width: 100%;
  padding:100px 0;
  position: relative;
  background: #fff;
}

.spinner-wrapper h1{
  font-size: 15px;
  font-weight: 600;
}



.spinner-wrapper.overlay{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: $OverlayZindex;
  background-color: rgba(0, 0, 0, .7);
}
.spinner-wrapper.overlay .ui.loader:before {
  border-color: rgba(255,255,255,.15);
}
.spinner-wrapper.overlay .ui.loader:after {
  border-color: #fff transparent transparent;
}
.spinner-wrapper.overlay h1{
  color: #fff;
  opacity: 0.9;
}
.spinner-wrapper.overlay p{
  color: #fff;
  opacity: 0.7;
}

.spinner-wrapper.white{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: $ZindexTopPriority;
  background-color: #fff;
}



/*-----------------------------------------------------------------------------
 ALERT MESSAGE
-------------------------------------------------------------------------------*/
.floating-alert-wrapper{
  position: fixed;
  z-index: $OverlayZindex!important;
  background-color: rgba(0, 0, 0, .7);
  top:0;
  right: 0;
  bottom: 0;
  left:0;
}


.floating-alert{
  margin:30px auto 0 auto;
  display: flex;
  background: #fff;
  max-width: 350px;
  justify-content: space-around;
  padding:15px;
}

.floating-alert>div:first-child{
  padding-right: 10px;
  flex-basis: 10%;
}
.floating-alert>div:last-child{
  flex-basis: 90%;
}
.floating-alert>div:first-child i{
  margin-top: 8px;
  font-size: 29px;
}
.floating-alert h1{
  font-weight: 700;
}

.floating-alert>div:last-child i{
  display: none;
}


/* Info */
.floating-alert.info{
  border-left: 3px solid #4580a8;
}
.floating-alert.info >div:first-child i{
  color: #4580a8;
}
.floating-alert.info{
   background: #cce8f4;
}
.floating-alert.info h1,
.floating-alert.info>div:last-child i{
  color: #4580a8;
}


/* Success */

.floating-alert.success{
  border-left: 3px solid #00ab53;
}
.floating-alert.success >div:first-child i{
  color: #00ab53;
}
.floating-alert.success{
   background: #b6ebcb;
}

.floating-alert.success h1,
.floating-alert.success>div:last-child i{
  color: #597054;
}



/* Warning */
.floating-alert.warning{
  border-left: 3px solid #ffa301;
}
.floating-alert.warning >div:first-child i{
  color: #a6772f;
}
.floating-alert.warning{
   background: #f8f3d6;
}

.floating-alert.warning h1,
.floating-alert.warning>div:last-child i{
  color: #a6772f;
}



/* Error */
.floating-alert.error{
  border-left: 3px solid #b42f28;
}
.floating-alert.error >div:first-child i{
  color: #b42f28;
}
.floating-alert.error{
   background: #ebc8c4;
}

.floating-alert.error h1,
.floating-alert.error>div:last-child i{
  color: #b42f28;
}


/*-----------------------------------------
  Alert Confirmation
-----------------------------------------*/
.alert-content-wrapper{
  text-align: center;
}

.alert-content-wrapper h1{
  font-weight: 700;
  margin-bottom: 3px;
}
.alert-content-wrapper img{
  margin:0 auto;
}

.ui.modal> .alert-footer-wrapper.actions {
  text-align: center;
}

img.spinner_image{
  margin-bottom: 10px;
  max-width: 50px;
}


/*-----------------------------------------
  BAGES ALERT MESSAGE
-----------------------------------------*/
/*-----------------------------------------
  ALERT MESSAGE
-----------------------------------------*/
.alert-message[disabled]{
  opacity: 0.5;
}
.alert-message.left{
  display: flex;
  padding:10px;
  border-radius: 5px;
}
.alert-message.centerFlex{
  align-items: center;
}
.alert-message.center{
  padding:10px;
  border-radius: 5px;
  text-align: center;
}
.alert-message h4{
  font-weight: 700;
  margin-bottom: 5px;
}
.alert-message p,
.alert-message span{
  font-size: 12px!important;
}
.alert-message span{
  font-weight: 700;
}

.alert-message i{
  margin-right: 10px;
}
.alert-message.info{
  background: #dff0ff;
}
.alert-message.info i{
  color: #2185d0;
}
.alert-message.error{
  background: #FFF6F6;
  //border:1px solid #e0b4b4;
}
.alert-message.error i{
  color: #db2828;
}
.alert-message.warning{
  background: #fffaf3;
  //border:1px solid #c9ba9b;
}
.alert-message.warning i{
  color: #c9ba9b;
}

.alert-message.success{
  background: #bffbe9;
  //border:1px solid #c9ba9b;
}
.alert-message.success i{
  color: #33dca9;
}


.alert-message.large{
  padding:30px 10px;
}
.alert-message.large i{
  font-size: 25px;
}
.alert-message-ontable .alert-message.error{
  border:none!important;
}


/*-----------------------------------------------------------------------------
 CUSTOM ALERT MESSAGE
-------------------------------------------------------------------------------*/
.custom-alert-wrapper{
  position: fixed;
  z-index: $OverlayZindex!important;
  background-color: rgba(0, 0, 0, .7);
  top:0;
  right: 0;
  bottom: 0;
  left:0;
}


.custom-alert{
  margin:30px auto 0 auto;
  display: flex;
  background: #fff;
  max-width: 500px;
  justify-content: space-around;
  padding:15px;
}

.custom-alert>div:first-child{
  padding-right: 5px;
  flex-basis: 8%;
}
.custom-alert>div:last-child{
  flex-basis: 92%;
}
.custom-alert>div:first-child i{
  margin-top: 5px;
  font-size: 24px;
}
.custom-alert h1{
  font-weight: 800;
  font-size: 17px;
}

.custom-alert>div:last-child i{
  display: none;
}

.custom-alert-footer{
  margin-top: 6px;
  font-weight: 700!important;
}


/* Info */
.custom-alert.info{
  border-left: 3px solid #4580a8;
}
.custom-alert.info >div:first-child i{
  color: #4580a8;
}
.custom-alert.info{
   background: #cce8f4;
}
.custom-alert.info h1,
.custom-alert.info>div:last-child i{
  color: #4580a8;
}


/* Success */

.custom-alert.success{
  border-left: 3px solid #00ab53;
}
.custom-alert.success >div:first-child i{
  color: #00ab53;
}
.custom-alert.success{
   background: #b6ebcb;
}

.custom-alert.success h1,
.custom-alert.success>div:last-child i{
  color: #597054;
}



/* Warning */
.custom-alert.warning{
  border-left: 3px solid #ffa301;
}
.custom-alert.warning >div:first-child i{
  color: #a6772f;
}
.custom-alert.warning{
   background: #f8f3d6;
}

.custom-alert.warning h1,
.custom-alert.warning>div:last-child i{
  color: #a6772f;
}



/* Error */
.custom-alert.error{
  border-left: 3px solid #b42f28;
}
.custom-alert.error >div:first-child i{
  color: #b42f28;
}
.custom-alert.error{
   background: #ebc8c4;
}

.custom-alert.error h1,
.custom-alert.error>div:last-child i{
  color: #b42f28;
}