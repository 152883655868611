/*---------------------------------
  RESET
----------------------------------*/
*{
  margin:0;
  padding: 0;
  box-sizing: border-box;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, textarea {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: $FontSize;
	vertical-align: baseline;
	color: $Color;
	font-family: $FontFamily;
}

html, body {
  font-family: $FontFamily;
  font-size: $FontSize;
	color: $Color;
	font-weight: normal;
  scroll-behavior: smooth;
  background: $BackgrondColor;
}
body.modal{
  overflow: hidden;
}

ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
}

img{
  display: block;
  margin:0;
  padding:0;
}


a{
  color: inherit;
}


h1,h2,h3,h4,h5{
  font-weight: normal;
  margin:0;
  padding:0;
}

h1{
  font-size: 20px;
}
h2{
  font-size: 18px;
}

.display_none{
  display: none;
}
#display_none{
  display: none;
}
.clearfix:after, .clearfix:before {
  content: " ";
  display: table;
}

body.noscroll {
  margin: 0; 
  height: 100%; 
  overflow: hidden
}