
/*----------------------------------------------------------
  BUTTON DEFAULT
-----------------------------------------------------------*/
.ui.button.tiny [class*=" uil-"]:before,
.ui.button.tiny [class^=uil-]:before{
	font-size: 17px;
}
.ui.button{
	//font-family: $FontFamily!important;
	font-weight: normal;
	color: $HeadingColor;
}
.ui.uppercase.button{
	text-transform: uppercase;
}
.ui.strong.button{
	font-weight: 800;
}

/*----------------------------------------------------------
  SPECIFIC BUTTON
-----------------------------------------------------------*/
.ui.PrimaryButton.button i{
	color: #fff;
}
.ui.PrimaryButton.button, .ui.PrimaryButton.buttons .button {
	background: #e41e2f;
  color: #fff!important;
}
.ui.PrimaryButton.button:hover, .ui.PrimaryButton.buttons:hover .button:hover,
.ui.PrimaryButton.button:focus, .ui.PrimaryButton.buttons:focus .button:focus {
	background: #e41e2f;
	color: #fff!important;
	opacity: 0.9;
}


.ui.DarkButton.button i{
	color: #fff;
}
.ui.DarkButton.button, .ui.DarkButton.buttons .button {
	background: #262626;
  color: #fff!important;
}
.ui.DarkButton.button:hover, .ui.DarkButton.buttons:hover .button:hover,
.ui.DarkButton.button:focus, .ui.DarkButton.buttons:focus .button:focus {
	background: #262626;
	color: #fff!important;
	opacity: 0.9;
}


.ui.BasicPrimary.button, .ui.BasicPrimary.buttons .button {
	background: #fff;
  border:1px solid $BorderColor;
}
.ui.BasicPrimary.button:hover, .ui.BasicPrimary.buttons:hover .button:hover,
.ui.BasicPrimary.button:focus, .ui.BasicPrimary.buttons:focus .button:focus {
	background: #f2f2f2;
	border:1px solid $BorderColor;
}



.ui.BasicButton.button, .ui.BasicButton.buttons .button {
	background: #ebedf1;
}
.ui.BasicButton.button:hover, .ui.BasicButton.buttons:hover .button:hover,
.ui.BasicButton.button:focus, .ui.BasicButton.buttons:focus .button:focus {
	background: #dfe1e9;
}



.ui.RedButton.button i{
	color: #a94658!important;
}
.ui.RedButton.button, .ui.RedButton.buttons .button {
  background-color: $ButtonRed;
	color: inherit!important;
}
.ui.RedButton.button:hover, .ui.RedButton.buttons:hover .button:hover,
.ui.RedButton.button:focus, .ui.RedButton.buttons:focus .button:focus {
	background-color: $ButtonRedHover;
	color: inherit!important;
}




// Green Button
.ui.GreenButton.button i{
	color: #fff;
}
.ui.GreenButton.button, .ui.GreenButton.buttons .button {
  background-color: $GreenButtonColor;
	color: #fff;
}
.ui.GreenButton.button:hover, .ui.GreenButton.buttons:hover .button:hover,
.ui.GreenButton.button:focus, .ui.GreenButton.buttons:focus .button:focus {
	background-color: $GreenButtonColor;
	opacity: 0.9;
	color: #fff;
}



.ui.TransButton.button i{
	color: #2fdaac;
}
.ui.TransButton.button, .ui.TransButton.buttons .button {
  background-color: #fff;
	color:#2fdaac;
}
.ui.TransButton.button:hover, .ui.TransButton.buttons:hover .button:hover,
.ui.TransButton.button:focus, .ui.TransButton.buttons:focus .button:focus {
	background-color: #f2f2f2;
	color:#25ca9e;
	opacity: 0.9;
}




.ui.SideBarButton.button i{
	color: #fff!important;
}
.ui.SideBarButton.button, .ui.SideBarButton.buttons .button {
  background-color: $SidebarButton;
	color: #fff!important;
}
.ui.SideBarButton.button:hover, .ui.SideBarButton.buttons:hover .button:hover,
.ui.SideBarButton.button:focus, .ui.SideBarButton.buttons:focus .button:focus {
	background-color: $SidebarButtonHover;
	color: #fff!important;
}


.ui.button [class*=" uil-"]:before,
.ui.button [class^=uil-]:before{
  margin: 0 0.42857143em 0 -0.21428571em;
}


.ui.SubmitButton.button {
  min-height: 1em;
  padding: 0.98571429em 1.5em 0.98571429em!important;
}
.ui.SubmitButton.buttons .disabled.button,
.ui.SubmitButton.disabled.button,
.ui.SubmitButton.button:disabled,
.ui.SubmitButton.disabled.button:hover,
.ui.SubmitButton.disabled.active.button {
  opacity: .5 !important;
}

.ui.SubmitButton.Isloading.button{
	padding: 0.88571429em 1.5em 0.78571429em!important;
}



/*-----------------------------
  LOADER BUTTON CUSTOM
-------------------------------*/
.ui.SubmitButton.button .ui.loader,
.ui.SubmitButton.button .ui.loader {
	width: 1.28571429rem;
  height: 1.28571429rem;
  font-size: .5em!important;
}
.ui.SubmitButton.button .ui.loader:before,
.ui.SubmitButton.button .ui.loader:after {
	width: 1.28571429rem;
  height: 1.28571429rem;
  margin: 0em 0em 0em -1.14285714rem;
}
.ui.SubmitButton.button .ui.loader:before {
  position: absolute;
  content: '';
  top: 0%;
  left: 50%;
  width: 100%;
  height: 100%;
  border-radius: 500rem;
  border: 0.2em solid rgba(0, 0, 0, 0.1);
}
.ui.SubmitButton.button .ui.loader:after {
  position: absolute;
  content: '';
  top: 0%;
  left: 50%;
  width: 100%;
  height: 100%;
  animation: loader 0.6s linear;
  animation-iteration-count: infinite;
  border-radius: 500rem;
  border-color: #fff transparent transparent;
  border-style: solid;
  border-width: 0.2em;
  box-shadow: 0px 0px 0px 1px transparent;
}


.btnTransparent{
	background: transparent!important;
	margin:0!important;
}


/*----------------------------------------------------------
  TABLE
-----------------------------------------------------------*/

.panel-table-header .ui.form .fields {
  align-items: center;
  margin:0;
}
.panel-table-header .ui.selection.dropdown{
  font-weight: normal;
}

.ui.table{
	border-radius: 5px;
}
.ui.table thead th {
  background: #f2f3f6;
	padding: 9px;
	color: $HeadingColor!important;
}




/*----------------------------------------------------------
  FORMS
-----------------------------------------------------------*/
.ui.form input:not([type]),
.ui.form input[type=date],
.ui.form input[type=datetime-local],
.ui.form input[type=email],
.ui.form input[type=file],
.ui.form input[type=number],
.ui.form input[type=password],
.ui.form input[type=search],
.ui.form input[type=tel],
.ui.form input[type=text],
.ui.form input[type=time],
.ui.form input[type=url],
.ui.form textarea{
	font-family: $FontFamily;
  border:1px solid $BorderColor;
  font-size: $FontSize;
}
.ui.form .field>label {
  display: block;
  color: inherit;
  font-weight: normal;
  text-transform: none;
	font-size: $FontSize;
}

.ui.form .field .prompt.label {
  font-weight: normal;
}
.ui.form .field .prompt.label.errorlabel{
  white-space: normal;
  background: #fff!important;
  border: none!important;
  color: #9f3a38!important;
  padding-left: 0;
	font-size: 12px;
}

.ui.form .field .prompt.label.errorlabel [class*=" uil-"]:before,
.ui.form .field .prompt.label.errorlabel [class^=uil-]:before{
	color: #9f3a38!important;
	margin-top: -2px;
  margin-right: 3px;
	font-size: 12px!important;
	vertical-align: middle;
}

.ui.disabled.input,
.ui.input:not(.disabled) input[disabled] {
  opacity: 0.8;
	background: #f2f2f2;
}

.customLabel-error{
	color: #9f3a38!important;
	padding-top: 3px;
	display: block;
}
