/*-----------------------------------------------------------------------------------------
    HEADER
------------------------------------------------------------------------------------------*/
.appWrapper-header{
  background: $HeaderBgColor;
  border-bottom: 1px solid $HeaderBOrderColorFade;
}

.appWrapper-header-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.appWrapper-header .appWrapper-logo img{
  max-width: 200px;
  margin:0 auto;
}


.appWrapper-header ul li{
  text-align: left;
  display: inline-block;
  position: relative;
}
.appWrapper-header ul li a{
  padding:13px 20px;
  display: block;
}


.appWrapper-header ul li a:hover,
.appWrapper-header ul li a:focus{
  cursor: pointer;
  color: inherit;
}


.appWrapper-user-divider{
  display: flex;
  align-items: center;
}

.appWrapper-user-divider .item-cart {
  display: inline-block;
  line-height: 0px;
  border-radius: 50%;
  background: $BgRed;
}

.appWrapper-user-divider .item-cart span {
  display: inline-block;
  padding-top: 50%;
  padding-bottom: 50%;
  margin-left: 6px;
  margin-right: 6px;
  color: #fff;
  font-size: 9px;
}


.appWrapper-header [class*=" flaticon-"]:after,
.appWrapper-header [class*=" flaticon-"]:before,
.appWrapper-header [class^=flaticon-]:after,
.appWrapper-header [class^=flaticon-]:before {
    font-size: 32px;
    margin-right: 5px;
    vertical-align: middle;
}


/*--------------------------------
    HEADER COLOR & BACKGROUND
---------------------------------*/
.appWrapper-user ul li{
  border-left:1px solid $HeaderBOrderColorFade;
}

.appWrapper-user i,
.appWrapper-user a{
  color: $HeaderColor;
}
.appWrapper-user ul li:hover,
.appWrapper-user ul li:focus{
  background: $HeaderBgColorHover;
}
.appWrapper-user ul li a:hover,
.appWrapper-user ul li a:focus{
  color: $HeaderColor;
}

.appWrapper-user ul li:hover a,
.appWrapper-user ul li:hover i{
  color: $HeaderColorHover;
}





/*--------------------------------
    Hamburger and Media Queries
---------------------------------*/
.appWrapper-logo.close .hamburger{
  display: block;
  padding-top: 15px;
  padding-left: 25px;
}
.appWrapper-logo.open .hamburger{
  display: block;
  padding-top: 3px;
  padding-left: 35px;
}


.appWrapper-logo.close .hamburger [class*=" flaticon-"]:after,
.appWrapper-logo.close .hamburger [class*=" flaticon-"]:before,
.appWrapper-logo.close .hamburger [class^=flaticon-]:after,
.appWrapper-logo.close .hamburger [class^=flaticon-]:before {
    font-size: 25px;
    margin:0;
}
.appWrapper-logo.open .hamburger [class*=" flaticon-"]:after,
.appWrapper-logo.open .hamburger [class*=" flaticon-"]:before,
.appWrapper-logo.open .hamburger [class^=flaticon-]:after,
.appWrapper-logo.open .hamburger [class^=flaticon-]:before {
    font-size: 18px;
    margin:0;
}



.appWrapper-logo img{
  display: none;
}
@media screen and (min-width: 768px) {
  .appWrapper-logo img{
    display: block;
  }
  .appWrapper-logo.open .hamburger,
  .appWrapper-logo.close .hamburger{
    display: none;
  }
}

/*--------------------------------
    Mobile Logo
---------------------------------*/
.appWrapper-mobile-logo{
   display: block;
   border-bottom: 1px solid $BorderColor;
   padding: 10px 5px;
}
.appWrapper-mobile-logo img{
  max-width: 200px;
  margin:0 auto;
}

@media screen and (min-width: 768px) {
  .appWrapper-mobile-logo{
     display: none;
  }
}


/*-----------------------------------------------------------------------------------------
    MENU
------------------------------------------------------------------------------------------*/
.appWrapper-menu{
  background: $MenuBg;
  width: 100%;
  border-bottom: 1px solid $HeaderBorderColor;
}

.appWrapper-menu ul li{
  text-align: center;
  display: inline-block;
  position: relative;
}
.appWrapper-menu ul li a{
  padding:6px 20px;
  display: block;
}
.appWrapper-menu ul li a,
.appWrapper-menu ul li a span{
  color: $MenuColor;
}
.appWrapper-menu ul li a i{
  color: $MenuIconColor;
}

.appWrapper-menu ul li a:hover,
.appWrapper-menu ul li a:focus{
  cursor: pointer;
  color: inherit;
}
.appWrapper-menu ul li span{
  display: block;
}

.appWrapper-menu ul li.active,
.appWrapper-menu ul li:hover{
  background: $MenuActiveBg;
}

.appWrapper-menu ul li.active a,
.appWrapper-menu ul li.active a span,
.appWrapper-menu ul li:hover a,
.appWrapper-menu ul li:hover a span{
  color: $MenuActiveColor;
}
.appWrapper-menu ul li.active a i,
.appWrapper-menu ul li:hover a i{
  color: $MenuActiveIconColor;
}

.appWrapper-menu{
  display: none;
}
@media screen and (min-width: 768px) {
  .appWrapper-menu{
    display: block;
  }
}
