/*------------------------------------------------------------------
 OVERLAY MODAL
--------------------------------------------------------------------*/
.modalpanel-wrapper{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $ZindexPriority!important;
  width: 100%;
  background-color: rgba(0, 0, 0, .7);
}

.modalpanel-container{
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  flex-shrink: 0;
  width: 70%;
  margin:auto auto auto 0;
  height: calc(100% - 0px);
  background: #fff;
}


.modalpanel-body{
  overflow: auto;
  padding:20px;
  padding-bottom: 150px;
}


.modalpanel-footer{
  margin-top: auto;
  flex: 0 0 auto 0;
  padding:15px 20px;
  border-top: 1px solid $BorderColorDark;
  background: #f9fafb;
}
.modalpanel-footer.right{
  text-align: right;
}


.modalpanel-footer-container{
  display: flex;
  justify-content: space-between;
}

.modalpanel-footer-container>div:first-child{
  flex-basis: 40%;
}
.modalpanel-footer-container>div:last-child{
  flex-basis: 58%;
}


/*--------------------------------
    MEDIA QUERIES HAS TOP
---------------------------------*/
.modalpanel-wrapper.top{
   padding-top: $TopMarginOnMobile;
}
@media screen and (min-width: 768px) {
  .modalpanel-wrapper.top{
     padding-top: $TopMargin;
  }
}



/*---------------------------
 MODAL POSITIONS
-----------------------------*/
.modalpanel-container.center{
  margin:0 auto;
}
.modalpanel-container.right{
  margin:0 0 auto auto;
}



/*---------------------------
 MODAL SIZES
-----------------------------*/
.modalpanel-container.tiny{
  width: 90%;
}
.modalpanel-container.small{
  width: 90%;
}
.modalpanel-container.medium{
  width: 90%;
}


/*---------------------------
 OVERLAY MODAL header
-----------------------------*/
.modalpanel-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $BorderColorDark;
  background: #f9fafb;
}
.modalpanel-header h2{
  font-weight: 800;
  text-transform: uppercase;
}


.modalpanel-header>div{
  padding:13px 20px;
}
.modalpanel-header>div:first-child{
  flex-basis: 70%;
}
.modalpanel-header>div:last-child{
  flex-basis: 30%;
  text-align: right;
}
.modalpanel-header>div:last-child:hover{
  cursor: pointer;
}



/*---------------------------
 MEDIA QUERIES
-----------------------------*/
@media screen and (min-width: 1024px) {
  .modalpanel-container.tiny{
    width: 40%;
  }
  .modalpanel-container.small{
    width: 50%;
  }
  .modalpanel-container.medium{
    width: 70%;
  }
}
@media screen and (min-width: 1366px) {
  .modalpanel-container.tiny{
    width: 25%;
  }
  .modalpanel-container.small{
    width: 40%;
  }
  .modalpanel-container.medium{
    width: 60%;
  }
}
@media screen and (min-width: 1680px) {
  .modalpanel-container.tiny{
    width: 18%;
  }
  .modalpanel-container.small{
    width: 28%;
  }
  .modalpanel-container.medium{
    width: 50%;
  }
}
