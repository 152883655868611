
/*------------------------------------------------
 INFO STORE OPENING AND ADDRESS
------------------------------------------------*/
.appWrapper-address{
  background: $PrimaryColor;
  border-radius: 30px;
  padding:20px;
  margin-bottom: 30px;
  position: relative;
}
.info-wrapper:after {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: 116% -5%;
    background-image: url( '../images/circle-svg.svg' );
    z-index: 3;
}


.info-wrapper>div{
  margin-bottom: 15px;
}

.info-container{
  display: flex;
  justify-content: space-between;
}
.info-container>div{
  flex-basis: 50%;
}

.info-wrapper h2.info-title{
  color: #fff;
  text-transform: uppercase;
  font-weight: 800;
  margin-bottom: 15px;
}


.info-container h2{
  font-weight: 600;
  color: #fff;
}

.info-wrapper h2.info-title [class^="flaticon-"]:before,
.info-wrapper h2.info-title [class*=" flaticon-"]:before,
.info-wrapper h2.info-title [class^="flaticon-"]:after,
.info-wrapper h2.info-title [class*=" flaticon-"]:after {
  font-size: 20px;
  font-weight: 800;
  margin-right: 15px;
}


.hours-wrapper{
  display: flex;
  justify-content: space-between;
}
.hours-wrapper>div{
  flex-basis: 50%;
}

.infofooter-description{
  margin-bottom: 5px;
  color: #fff;
  margin-bottom: 20px;
}

.appWrapper-address p.red{
  color: #fff;
  font-weight: 700;
}
.appWrapper-address p.green{
  color: #fff;
  font-weight: 700;
}

.info-wrapper p,
.info-wrapper a{
  margin:5px 0;
  color: #fff;
}


@media screen and (min-width: 768px) {
  .info-wrapper{
    display: flex;
    justify-content: space-between;
  }
  .info-wrapper>div{
    flex-basis: 50%;
    margin-bottom: 0;
  }
  .info-wrapper:after {
    background-size: 34%;
    background-position: 106% -24%;
  }
}

@media screen and (min-width: 1024px) {
  .info-wrapper:after {
    background-size: 28%;
    background-position: 106% -24%;
  }
}
@media screen and (min-width: 1366px) {
  .info-wrapper:after {
    background-size: 20%;
    background-position: 106% -24%;
  }
}
/*------------------------------------------------
 FOOTER
------------------------------------------------*/


.appWrapper-footer{
  background: $FooterBg;
}
.appWrapper-footer p,
.appWrapper-footer a{
  color: $FooterColor;
  margin:5px 0;
}
.appWrapper-footer a:hover{
  text-decoration: underline;
}



/*------------------------------------------------
 SUBSCRIBER
------------------------------------------------*/
.appWrapper-subscribe{
  margin-bottom: 30px;
  border-bottom: 1px solid $FooterBorder;
  padding-bottom: 20px;
}
.appWrapper-subscribe h2{
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
}

@media screen and (min-width: 1024px) {
  .appWrapper-subscribe-container{
    display: flex;
    align-items: center;
  }
  .appWrapper-subscribe-container>div{
    flex-basis: 50%;
  }
}




/*------------------------------------------------
 INFO & LOGO FOOTER
------------------------------------------------*/
.appWrapper-info{
  padding:50px 5px;
}

.appWrapper-info-container>div{
  margin-bottom: 35px;
}
.appWrapper-info-container>div:last-child{
  margin-bottom: 0;
}

.companyInfo img{
  max-width: 200px;
  margin-bottom: 5px;
}
.companyInfo i{
  font-size: 18px;
}
.appWrapper-info-container h2{
  color: #fff;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: 700;
}


/*------------------------------------------------
 COPYRIGHT FOOTER
------------------------------------------------*/
.appWrapper-copyright{
  border-top: 1px solid $FooterBorder;
}
.appWrapper-copyright-container{
  padding: 15px 5px;
  text-align: center;
}
.appWrapper-copyright-container>div:last-child{
  text-align: center;
}
.appWrapper-copyright-container>div:last-child span{
  margin: 0 10px;
}


@media screen and (min-width: 768px) {

  /*------------------------------------------------
   INFO & LOGO FOOTER
  ------------------------------------------------*/

  .appWrapper-info-container{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .appWrapper-info-container>div{
    flex-basis: 49.33%;
  }
  .appWrapper-info-container>div{
    margin-bottom: 20px;
  }


  /*------------------------------------------------
   COPYRIGHT FOOTER
  ------------------------------------------------*/
  .appWrapper-copyright-container{
    display: flex;
    justify-content: space-between;
    text-align: left;
  }
  .appWrapper-copyright-container>div{
    flex-basis: 50%;
  }
  .appWrapper-copyright-container>div:last-child{
    text-align: right;
  }
  .appWrapper-copyright-container>div:last-child span{
    margin-left: 10px;
  }

}



@media screen and (min-width: 1024px) {
  .appWrapper-info-container{
    display: flex;
    justify-content: space-between;
    flex-wrap:nowrap;
  }
  .appWrapper-info-container>div{
    flex-basis: 24.33%;
  }
  .appWrapper-info-container>div{
    margin-bottom: 0;
  }
}
