/*---------------------------------
  SCROLLBAR SIDEBAR
----------------------------------*/

/* width */
.sidebar-menu::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.sidebar-menu::-webkit-scrollbar-track {
  border-radius: 6px;
}

/* Handle */
.sidebar-menu::-webkit-scrollbar-thumb {
  background: $ScrollBarBgThumb;
  border-radius: 6px;
}

/* Handle on hover */
.sidebar-menu::-webkit-scrollbar-thumb:hover {
  background: $ScrollBarBgThumb;
}
