/*
  Flaticon icon font: Flaticon
  Creation date: 12/11/2020 03:21
  */

@font-face {
font-family: "Flaticon";
src: url("flaticon/Flaticon.eot");
src: url("flaticon/Flaticon.eot?#iefix") format("embedded-opentype"),
     url("flaticon/Flaticon.woff2") format("woff2"),
     url("flaticon/Flaticon.woff") format("woff"),
     url("flaticon/Flaticon.ttf") format("truetype"),
     url("flaticon/Flaticon.svg#Flaticon") format("svg");
font-weight: normal;
font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
@font-face {
  font-family: "Flaticon";
  src: url("flaticon/Flaticon.svg#Flaticon") format("svg");
}
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
      font-family: Flaticon;
      font-size: 20px;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      speak: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
}


.flaticon-success:before { content: "\f100"; }
.flaticon-cloud:before { content: "\f101"; }
.flaticon-placeholder:before { content: "\f102"; }
.flaticon-search:before { content: "\f103"; }
.flaticon-user:before { content: "\f104"; }
.flaticon-smartphone:before { content: "\f105"; }
.flaticon-placeholder-1:before { content: "\f106"; }
.flaticon-star:before { content: "\f107"; }
.flaticon-internet:before { content: "\f108"; }
.flaticon-star-1:before { content: "\f109"; }
.flaticon-error:before { content: "\f10a"; }
.flaticon-calendar:before { content: "\f10b"; }
.flaticon-photo-camera:before { content: "\f10c"; }
.flaticon-plus:before { content: "\f10d"; }
.flaticon-settings:before { content: "\f10e"; }
.flaticon-database:before { content: "\f10f"; }
.flaticon-time:before { content: "\f110"; }
.flaticon-picture:before { content: "\f111"; }
.flaticon-user-1:before { content: "\f112"; }
.flaticon-placeholder-2:before { content: "\f113"; }
.flaticon-home:before { content: "\f114"; }
.flaticon-add:before { content: "\f115"; }
.flaticon-settings-1:before { content: "\f116"; }
.flaticon-hold:before { content: "\f117"; }
.flaticon-users:before { content: "\f118"; }
.flaticon-locked:before { content: "\f119"; }
.flaticon-info:before { content: "\f11a"; }
.flaticon-home-1:before { content: "\f11b"; }
.flaticon-menu:before { content: "\f11c"; }
.flaticon-search-1:before { content: "\f11d"; }
.flaticon-multiply:before { content: "\f11e"; }
.flaticon-picture-1:before { content: "\f11f"; }
.flaticon-locked-1:before { content: "\f120"; }
.flaticon-garbage:before { content: "\f121"; }
.flaticon-settings-2:before { content: "\f122"; }
.flaticon-layers:before { content: "\f123"; }
.flaticon-database-1:before { content: "\f124"; }
.flaticon-checked:before { content: "\f125"; }
.flaticon-push-pin:before { content: "\f126"; }
.flaticon-edit:before { content: "\f127"; }
.flaticon-menu-1:before { content: "\f128"; }
.flaticon-warning:before { content: "\f129"; }
.flaticon-server:before { content: "\f12a"; }
.flaticon-exit:before { content: "\f12b"; }
.flaticon-flag:before { content: "\f12c"; }
.flaticon-alarm:before { content: "\f12d"; }
.flaticon-layers-1:before { content: "\f12e"; }
.flaticon-smartphone-1:before { content: "\f12f"; }
.flaticon-smartphone-2:before { content: "\f130"; }
.flaticon-checked-1:before { content: "\f131"; }
.flaticon-power:before { content: "\f132"; }
.flaticon-unlocked:before { content: "\f133"; }
.flaticon-funnel:before { content: "\f134"; }
.flaticon-login:before { content: "\f135"; }
.flaticon-hide:before { content: "\f136"; }
.flaticon-folder:before { content: "\f137"; }
.flaticon-smartphone-3:before { content: "\f138"; }
.flaticon-locked-2:before { content: "\f139"; }
.flaticon-cart:before { content: "\f13a"; }
.flaticon-envelope:before { content: "\f13b"; }
.flaticon-truck:before { content: "\f13c"; }
.flaticon-shield:before { content: "\f13d"; }
.flaticon-customer-service:before { content: "\f13e"; }
.flaticon-facebook:before { content: "\f13f"; }
.flaticon-instagram:before { content: "\f140"; }
.flaticon-twitter:before { content: "\f141"; }
.flaticon-linkedin:before { content: "\f142"; }
.flaticon-user-2:before { content: "\f143"; }
.flaticon-truck-1:before { content: "\f144"; }
.flaticon-location:before { content: "\f145"; }
.flaticon-shopping-cart:before { content: "\f146"; }
.flaticon-tag:before { content: "\f147"; }
.flaticon-card:before { content: "\f148"; }
.flaticon-tag-1:before { content: "\f149"; }
.flaticon-truck-2:before { content: "\f14a"; }
.flaticon-shopping-bag:before { content: "\f14b"; }
.flaticon-shopping-bag-1:before { content: "\f14c"; }
.flaticon-email:before { content: "\f14d"; }
.flaticon-shopping-bag-2:before { content: "\f14e"; }
.flaticon-in:before { content: "\f14f"; }
.flaticon-name:before { content: "\f150"; }
.flaticon-identification:before { content: "\f151"; }
.flaticon-pen:before { content: "\f152"; }
.flaticon-calendar-1:before { content: "\f153"; }
.flaticon-user-3:before { content: "\f154"; }
.flaticon-user-4:before { content: "\f155"; }
.flaticon-user-5:before { content: "\f156"; }
.flaticon-right:before { content: "\f157"; }
.flaticon-event:before { content: "\f158"; }
.flaticon-profile:before { content: "\f159"; }
.flaticon-contact-information:before { content: "\f15a"; }
.flaticon-calendar-2:before { content: "\f15b"; }
.flaticon-cancel:before { content: "\f15c"; }
.flaticon-user-6:before { content: "\f15d"; }
.flaticon-event-1:before { content: "\f15e"; }
.flaticon-left-arrow:before { content: "\f15f"; }
.flaticon-bag:before { content: "\f160"; }
.flaticon-delete-user:before { content: "\f161"; }
.flaticon-menu-2:before { content: "\f162"; }
.flaticon-smartphone-4:before { content: "\f163"; }
.flaticon-user-7:before { content: "\f164"; }
.flaticon-user-8:before { content: "\f165"; }
.flaticon-refresh:before { content: "\f166"; }
.flaticon-calendar-3:before { content: "\f167"; }
.flaticon-writing:before { content: "\f168"; }
.flaticon-box:before { content: "\f169"; }
.flaticon-heart:before { content: "\f16a"; }
.flaticon-heart-1:before { content: "\f16b"; }
.flaticon-like:before { content: "\f16c"; }
.flaticon-heart-2:before { content: "\f16d"; }
.flaticon-heart-3:before { content: "\f16e"; }
