/*------------------------------------------------------
 PAGES GRID CONTAINER
------------------------------------------------------*/

.appWrapper-data{
  padding: 30px 0 50px 0;
}
.appWrapper-data.hasheader{
  padding: 0 0 50px 0;
  margin-top: -1%;
}
.appWrapper-grid{
  width: 100%;
  margin:0 auto;
  padding: 0 10px;
}

@media screen and (min-width: 1920px) {
  .appWrapper-grid{
    width: 70%;
    margin:0 auto;
  }
}

/*------------------------------------------------------
 PAGE TITLE
------------------------------------------------------*/
.appWrapper-title{
  margin-bottom: 10px;
}
.appWrapper-title.flex{
  padding:10px 0 5px 0;
}
.appWrapper-title.flex>div:last-child{
  text-align: left;
}
.appWrapper-title h2{
  font-weight: 800;
  font-size: 22px;
  color: $PrimaryColor
}


@media screen and (min-width: 768px) {
  .appWrapper-title.flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .appWrapper-title.flex>div{
    flex-basis: 50%;
  }
  .appWrapper-title.flex>div:last-child{
    text-align: right;
  }
}



.appWrapper-intro{
  width: 100%;
  position: relative;
}
.appWrapper-intro-container{
  background: $PrimaryColor;
  padding:30px 30px;
  width: 100%;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  position: relative;
  z-index: 2;
}



.appWrapper-intro h1{
  margin-bottom: 10px;
  color: #fff;
  font-weight:700;
}
.appWrapper-intro p{
  color: rgba(255,255,255,0.9);
  margin:3px 0;
}

.appWrapper-intro a{
  text-decoration: underline;
  font-weight: 700;
}
.appWrapper-intro a:hover{
  cursor: pointer;
  color: inherit;
}


@media screen and (min-width: 768px) {
  .appWrapper-intro-container{
    background: $PrimaryColor;
    padding:30px 30px;
    width: 93%;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 300px;
    position: relative;
    z-index: 2;
  }
  .appWrapper-intro:after {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    background-repeat: no-repeat;
    background-size: 25%;
    background-position: 100% -30%;
    background-image: url( '../images/bg-logo-circle-right.png' );
  }

  .appWrapper-intro-content:before {
    display: block;
    width: 20%;
    height: 100%;
    position: absolute;
    content: '';
    right: 5%;
    top: 0;
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: 94% 65%;
    background-image: url( '../images/circle-svg.svg' );
    z-index: 3;
  }
}


@media screen and (min-width: 1024px) {
  .appWrapper-intro:after {
    background-size: 19%;
    background-position: 100% -30%;
  }

  .appWrapper-intro:before {
    background-size: 19%;
    background-position: 94% 65%;
  }
  .appWrapper-intro-content:before {
    width: 20%;
    right: 5%;
    background-size: 70%;
    background-position: 94% 65%;
  }
}
@media screen and (min-width: 1366px) {
  .appWrapper-intro:after {
    background-size: 13%;
    background-position: 100% -30%;
  }

  .appWrapper-intro:before {
    background-size: 12%;
    background-position: 94% 65%;
  }

  .appWrapper-intro-content:before {
    width: 20%;
    right: 5%;
    background-size: 55%;
    background-position: 94% 65%;
  }

}
/*-----------------------------------------------------------
 CART PAGE
-----------------------------------------------------------*/
.cartFormWrapper{
  width: 100%;
}
@media screen and (min-width: 1920px) {
  .cartFormWrapper{
    width: 70%;
  }
}


/*-----------------------------------------------------------
 Contact US
-----------------------------------------------------------*/
.contactWrapper>div{
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 15px;
}

.contactDetails{
  display: flex;
  padding-bottom: 20px;
}
.contactDetails:last-child{
  padding-bottom: 0;
}
.contactDetails>div:last-child>p:first-child{
  font-weight: 700;
  color: $PrimaryColor;
}
.contactDetails i{
  color: $PrimaryColor;
}

@media screen and (min-width: 768px) {
  .contactWrapper{
    display: flex;
    justify-content: space-between;
  }
  .contactWrapper>div{
    flex-basis: 49%;
    margin-bottom: 0;
  }
}
