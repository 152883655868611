/*----------------------------------------------------------
  TABLE PANEL
-----------------------------------------------------------*/
.tableWrapper{
  margin-top: 5px;
}
.panel-table{
  background: #fff;
  border-radius: 5px;
}
.panel-table.custom{
  background: transparent!important;
}

.panel-table-header{
  font-weight: 600;
  margin-bottom: 10px;
  padding:10px 10px 0 10px;
}
.panel-table-header >div{
  margin: 10px;
}

.panel-table-body{
  padding:5px 10px 10px 10px;
}
.panel-table.custom .panel-table-body{
  padding:0!important;
}


.panel-table-footer{
  border-top: 1px solid $BorderColor;
  padding:20px 20px;
}
.panel-table-footer.text-right{
  text-align: right;
}

.table-responsive{
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}
.table-responsive.overflow{
  overflow: visible;
}

.ui.table td{
  vertical-align: middle!important;
}
.ui.table td img{
  max-width: 30px;
  display: inline-block!important;
  vertical-align: middle!important;
  margin:0 auto;
}
.ui.table td img.big{
  max-width: 100px;
  display: inline-block!important;
  vertical-align: middle!important;
  margin:0 auto;
}
.ui.table td p{
  font-size: 11px;
  color: rgba(0,0,0,.7);
}

.ui.table td.now-wrapp{
  white-space: nowrap;
}

.ui.table.noBorder,
.ui.table.noBorder td{
  border:none!important;
}

.profileTable{
  display: flex;
  align-items: center;
}
.profileTable>div:first-child{
  padding-right: 5px;
}
.profileTable img{
  max-width: 30px;
  border-radius: 50%;
}

.profileTable h3,
.descriptionTable h3{
  font-weight: 700;
  font-size: $FontSize!important;
}


.tblButtonEqual{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tblButtonEqual>div{
  padding:3px 3px;
}
.tblButtonEqual>div{
  width: 50%;
}
.tblButtonEqual>div .button{
  width: 100%!important;
}

@media screen and (min-width: 1024px) {
  .panel-table-header{
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .panel-table-header >div{
    flex-basis: 49%;
    margin: 0;
  }
  .panel-table-header >div:last-child{
    text-align: right;
  }
}



/*----------------------------------------------------------
  TABLE UI SELECT
-----------------------------------------------------------*/
.ui.table .ui.form .fields {
  margin: 0;
}
.ui.table .ui.selection.dropdown .menu {
  overflow: visible;
}
.ui.table .ui.form .fields > .field {
  padding-left: 0;
  padding-right: 0;
}


/*----------------------------------------------------------
  STATUS
-----------------------------------------------------------*/
.table-icon-wrapper{
  padding:2px 5px 2px 3px;
  border-radius: 3px;
}
.table-icon-wrapper{
  font-size: 12px;
}

.table-icon-wrapper [class*=" uil-"]:before,
.table-icon-wrapper [class^=uil-]:before{
  font-size: 15px;
}

//green
.table-icon-wrapper.positive{
  background: #33dca9;
  color: #fff;
}
.table-icon-wrapper.positive i{
  color: #fff;
}

//red
.table-icon-wrapper.negative{
  background: #fc4b6c;
  color: #fff;
}
.table-icon-wrapper.negative i{
  color: #fff;
}





/*----------------------------------------------------------
  STATUS V2
-----------------------------------------------------------*/
.status-icon-wrapper span{
  padding:4px 7px 4px 5px;
  border-radius: 3px;
  color: #344050;
}
.status-icon-wrapper span{
  font-size: 11px;
  margin-left: 3px;
}

.status-icon-wrapper [class*=" uil-"]:before,
.status-icon-wrapper [class^=uil-]:before{
  font-size: 20px;
}


//green
.status-icon-wrapper.positive span{
  background: #b6ebcb;
}
.status-icon-wrapper.positive i{
  color: #33dca9;
}

.status-icon-wrapper.warning span{
  background: #f7f3d8;
}
.status-icon-wrapper.warning i{
  color: #93742c;
}

//WARNING
.status-icon-wrapper.orange span{
  background: #f8f3d6;
}
.status-icon-wrapper.orange i{
  color: #111;
}

//red
.status-icon-wrapper.negative span{
  background: #fadde2;
}
.status-icon-wrapper.negative i{
  color: #fc4b6c;
}


.statusWrapperDetails{
  margin:5px 0;
  width: 100%;
}
@media screen and (min-width: 1366px) {
  .statusWrapperDetails{
    margin:5px 0;
    width: 30%;
  }
}


/*----------------------------------------------------------
  SEARCH
-----------------------------------------------------------*/
.search-wrapper{
   width: 100%;
   padding: 10px;
   background: #fff;
   border-radius: 5px;
   margin:10px 0;

}


/*----------------------------------------------------------
  TABLE PAGINATIONS
-----------------------------------------------------------*/
.mypaginations ul li{
  display: inline-block;
}
.mypaginations ul li a{
  font-weight: 700;
  margin:0 3px;
  border-radius: 5px;
  border:1px solid $BorderColor;
  width: 20px;
  padding:5px 10px;
  color: $HeadingColor!important;
}
.mypaginations ul li a:hover{
  cursor: pointer;
}
.mypaginations>div ul li a i{
  color: #111!important;
}
.mypaginations>div ul li a[disabled] i{
  color: #111!important;
  opacity: 0.5;
}
.mypaginations ul li a.is-current{
  background: #262626;
  border:1px solid #262626;
  border-radius: 5px;
  font-weight: 700;
  color: #d9dadd!important;
}
.pageinfotext{
  color: $HeadingColor!important;
  font-weight: 700;
}



@media screen and (max-width: 767px) {
  .mypaginations>div{
    text-align: center;
    margin:10px 0;
  }
}
@media screen and (min-width: 768px) {
  .mypaginations{
    display: flex;
    justify-content: space-between;
  }
}
